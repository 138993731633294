import api from "@/api/laravelApi";

//INICIO RECEPCION_PROYECTOS
export const getProjectsByStatus = async({ commit }) => {
    try {
        const { data: info } = await api.get('revisor/obtenerProyectosRevision');
        const { status, data, message } = info;

        if (status != "success") return { ok: false, message };

        else if (status == 'success') commit("setProjectsReception", { data });

        return { ok: true, message };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }
};

export const sendToRevision = async(_, projectId) => {

    try {

        const { data } = await api.post('/revisor/asignarRevisorProyecto', {
            idProyecto: projectId
        });
        const { status, message } = data;

        return { status, message };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }
};
//FIN RECEPCION_PROYECTOS

//INICIO REVISION_PROYECTOS
export const getProjectsRevision = async({ commit }) => {

    try {

        const { data: info } = await api.get(`revisor/obtenerProyectosRevisor`);
        const { status, data, message } = info;

        if (status != "success") return { ok: false, message };

        commit("setProjectsRevision", { data });

        return { ok: true, message };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

};

export const getProject = async(_, id) => {

    try {

        const { data: dataResponse } = await api.get(`/revisor/obtenerProyectoPorId/${id}`);
        const { status, message, data } = dataResponse;

        return { status, message, data };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }
};

export const sendRequestDictamen = async(_, { projectId, tecnico }) => {

    try {

        const { data } = await api.post("/revisor/solicitarDictamen", {
            idProyecto: projectId,
            idDireccion: tecnico,
        });
        const { status, message } = data;

        if (status != "success") return { ok: false, message };

        return { ok: true, message };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

};

export const getInputsForm = async({ commit }, idForm) => {

    try {
        const { data: info } = await api.get(`/revisor/obtenerFormularioDinamico/${idForm}`);
        const { status, data } = info;

        if (status == "success" && idForm == 3) commit("setInputsForm", data);
        else if (status == "success" && idForm == 2) commit("setInputsPA", data);
        else if (status == "success" && idForm == 1) commit("setInputsPE", data);

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

};

export const getCatalogos = async() => {
    try {

        const { data } = await api.get("/revisor/obtenerCatalogo/0");
        const { status, message, data: info } = data;

        if (status != "success") return { ok: false, message };

        return { ok: true, message, data: info };
    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }
};

export const getDictamen = async(_, project_id) => {
    try {
        const { data: info } = await api.post(
            `/mesa/revision/getDictamen/${project_id}`
        );
        const { status, dictamen, message } = info;

        if (status != "success") return { ok: false, message };

        return { ok: true, message, dictamen };
    } catch (err) {
        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };
    }
};


//OBTENCIÓN DE CONVENIOS 

export const getConvenios = async({ commit }) => {

    try {

        const { data: info } = await api.get("/revisor/obtenerConveniosDependencias");
        const { status, data } = info;

        if (status == "success") commit("setConvenios", { data });

        return status;

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

};

//DESCARGAR CONVENIOS

export const descargarConvenio = async(_, { upp }) => {

    try {
        const { data } = await api.get(`/revisor/descargarConvenioDependencia/${upp}`, {
            responseType: "blob",
        });

        return data;

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }
}

//ENVIAR OBSERVACIÓN SOBRE CONVENIO

export const enviarObservacionConvenio = async(_, { arrayData }) => {

    try {
        const { data } = await api.post('/revisor/enviarObservacionConvenio', {
            upp: arrayData[0],
            observacion: arrayData[1],
        });

        const { status, message } = data;

        return { status, message };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }
}

//OBTENER PLANES ANUALES

export const getPlanesAnuales = async({ commit }) => {

    try {

        const { data } = await api.get('/revisor/obtenerAniosPlanAnual');

        const { status, data: planesAnuales } = data;

        if (status == 'success') commit('setPlanesAnuales', planesAnuales);

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

//OBTENER MESES PLAN ANUAL

export const getMesesPlanAnual = async(_, planAnual) => {

    try {

        const { data } = await api.get(`/revisor/obtenerMesesPlanAnual/${planAnual}`);

        const { data: meses } = data;

        return { meses };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

//OBTENER DIAS DEL MES

export const getDiasMes = async(_, arrayData) => {

    try {

        const { data } = await api.get(`/revisor/obtenerDiasMesPlanAnual/${arrayData[0]}/${arrayData[1]}`);

        const { data: dias } = data;

        return { dias };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

//GUARDAR FECHA LIMITE

export const guardarFechaLImite = async(_, arrayData) => {

    try {

        const { data } = await api.post('/revisor/guardarFechaLimiteReportes', {

            planAnual: arrayData[0],
            mes: arrayData[1],
            dia: arrayData[2]

        });

        const { data: info, message, status } = data;

        return { info, message, status };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

//OBTENER FECHAS LIMITE PARA SUBIR REPORTES DE CADA MES DEL PLAN ANUAL

export const getFechasLimiteReportes = async(_, planAnual) => {

    try {

        const { data } = await api.get(`/revisor/obtenerFechasLimite/${planAnual}`);

        const { data: info, message, status: estado } = data;

        return { info, message, estado };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const getDependencias = async({ commit }) => {

    try {

        const { data } = await api.get(`/revisor/obtenerEntidadesUppUr/1/0`);
        const { status, data: dataReturn } = data;

        if (status == 'success') commit('setDependencias', dataReturn);

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const obtenerPlanes = async( { commit }, idDependencia) => {

    try {

        const { data } = await api.get(`/revisor/obtenerPlanes/${idDependencia}`);
        const { status, data: dataReturn } = data;

        if (status == 'success') {
            commit('setPlanesDependencia', dataReturn);
        }
        
        return { status, dataReturn }; 
    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const obtenerCatalogos = async({ commit }) => {

    try {
       
        const objectCatalogos = {
            'CatalogoImpacto': 'setCatalogoImpactosProyecto',
            'CatalogoTipologia': 'setCatalogoTiposProyecto',
            'CatalogoPoblacionObjetivo': 'setCatalogoPoblacionObjetivo',
            'CatalogoEjecucionProyecto': 'setCatalogoEjecucionProyecto',
            'CatalogoBrindaraServicio': 'setCatalogoBrindaraServicio',
            'CatalogoAsignacionResolucion': 'setCatalogoAsignacionResolucion',
            'CatalogoDirecciones': 'setCatalogoDirecciones',
            'CatalogoPrioridad': 'setCatalogoPrioridad',
            'CatalogoTipoUsuario': 'setCatalogoTipoUsuario',
            'CatalogoAsignacion': 'setCatalogoAsignacion',
            'CatalogoDireccion': 'setCatalogoDireccion'
        };

        const { data } =  await api.get(`/revisor/obtenerCatalogos`);
        const { status, data: info } = data;
       
        if( status == 'success'){
             
            for ( const key in info ) {
                commit(`${objectCatalogos[key]}`, info[key] );
            }

        }

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const getOficinasDependencia = async({ commit }, upp) => {

    try {

        const { data } = await api.get(`/revisor/obtenerEntidadesUppUr/2/${upp}`);

        const { status, data: oficinas } = data;

        if (status == 'success') commit("setOficinas", oficinas);

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

//PLAN ESTRATÉGICO
export const getProyectosFechasSugeridas = async({ commit }, idDependencia) => {

    try {
        const { data } = await api.get(`/revisor/obtenerProyectosFechasSugeridas/${idDependencia}`);

        const { data: proyectos, status } = data;

        if (status == 'success') commit("setProyectos", proyectos);

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }


}

//------------------------- Obtener inventario de proyectos de la dependencia ------------------------------------------//

export const getInventarioProyectos = async({ commit }, idDependencia) => {

    try {

        const { data } = await api.get(`/revisor/obtenerInventarioDependenciaSistemas/${idDependencia}`);
        const { data: inventarioProyectos, status } = data;
        if (status == 'success') commit("setInventarioProyectos", inventarioProyectos);

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }
}

//------------------------- Obtener inventario de proyectos de la dependencia ------------------------------------------//

export const marcarObservacionPE = async(_, formData) => {

    try {

        const { data } = await api.post(`/revisor/guardarObservacionPE`, formData);
        const { status, message } = data;
        return { status, message };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}


//------------------------- Obtener inventario de proyectos de la dependencia ------------------------------------------//

export const getObservacionPE = async(_, idPlanEstrategico) => {

    try {

        const { data } = await api.get(`/revisor/obtenerObservacionesPlanEstrategico/${idPlanEstrategico}`);
        const { status, message } = data;
        return { status, message };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}


//------------------------- Obtener archivos revisor ------------------------------------------//

export const downloadFile = async(_, dataSend) => {

    try {

        let uri;

        if (dataSend.formulario == 1) {

            uri = `/revisor/descargarObservacionPlanPE/${dataSend.formulario}/${dataSend.upp}/${dataSend.clave}`;

        } else if (dataSend.formulario == 2) {

            uri = `/revisor/descargarObservacionPlanPA/${dataSend.formulario}/${dataSend.upp}/${dataSend.clave}/${dataSend.plan_anual}`;

        } else if (dataSend.formulario == 3) {

            uri = `/revisor/descargarObservacionFT/${dataSend.formulario}/${dataSend.upp}/${dataSend.clave}/${dataSend.idProyecto}`;

        }

        const { data } = await api.get(uri, {
            responseType: "blob"
        });

        return data;

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const marcarObservacionPA = async(_, formData) => {

    try {

        const { data } = await api.post(`/revisor/guardarObservacionPA`, formData);
        const { status, message } = data;
        return { status, message };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const marcarObservacionFT = async(_, formData) => {

    try {

        const { data } = await api.post(`/revisor/guardarObservacionFT`, formData);
        const { status, message } = data;
        return { status, message };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const descargarArchivoPlanEstrategico = async(_, dataSend) => {

    try {

        const { data: dataResponse } = await api.get(`/revisor/descargarArchivoPlanEstrategico/${dataSend.seccion}/${dataSend.upp}`, {
            responseType: "blob",
        });

        return dataResponse;

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const descargarArchivoProyecto = async(_, dataSend) => {

    try {

        const { data: dataResponse } = await api.get(`/revisor/descargarArchivoProyecto/${dataSend.idProyecto}/${dataSend.seccion}/${dataSend.clave}/${dataSend.upp}`, {
            responseType: "blob",
        });

        return dataResponse;

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const aceptarProyecto = async(_, dataSend) => {

    try {

        const { data: dataResponse } = await api.post(`/revisor/enviarConsejoProyecto`, dataSend);

        return dataResponse;

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const aceptarPE = async(_, dataSend) => {

    try {

        const { data: dataResponse } = await api.post(`/revisor/enviarConsejoPE`, dataSend);

        return dataResponse;

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}


export const aceptarPA = async(_, dataSend) => {

    try {

        const { data: dataResponse } = await api.post(`/revisor/enviarConsejoPA`, dataSend);

        return dataResponse;

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const getRevisores = async({ commit }) => {

    try {

        const { data } = await api.get(`/revisor/obtenerRevisores`);
        const { data: dataResponse, status } = data;
        if (status == 'success') commit("setRevisores", dataResponse);
        return { status };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const getProyectosRevisores = async({ commit }) => {

    try {

        const { data } = await api.get(`/revisor/obtenerProyectosRevisores`);
        const { data: dataResponse, status } = data;
        if (status == 'success') commit("setProyectosRevisores", dataResponse);
        return { status };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const reasignarRevisor = async(_, dataSend) => {

    try {

        const { data } = await api.post(`/revisor/reasignarRevisor`, dataSend);
        const { status, message } = data;
        return { status, message };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const obtenerArchivoPlanEstrategico = async(_, data) => {

    try {

        const { data: dataResponse } = await api.get(`/revisor/obtenerArchivoPlanEstrategico/${data.upp}/${data.seccion}`);

        return dataResponse;

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}


export const obtenerArchivoProyecto = async(_, data) => {

    try {
        const { data: dataResponse } = await api.get(`/revisor/obtenerArchivoProyecto/${data.upp}/${data.idProyecto}/${data.seccion}/${data.clave}`);

        return dataResponse;

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

/*export const getInfoReports = async(_, idProyecto) => {

    try {

        const { data: info } = await api.get(`/revisor/obtenerSeguimientoProyecto/${idProyecto}`);
        const { status, data } = info;
        return { status, data };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

};*/


export const obtenerReporte = async(_, data) => {

    try {

        if (data.action == 1) {

            const { data: file } = await api.get(`/revisor/descargarReporteProyecto/${data.idProyecto}/${data.mes}`, {
                responseType: "blob",
            });

            return file;

        } else if (data.action == 2) {

            const { data: dataResponse } = await api.get(`/revisor/obtenerReportePDF/${data.idProyecto}/${data.mes}`);
            const { status, file } = dataResponse;
            if (status == 'success') return { status, file };
            else return { status };

        }


    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

};

export const ejecutarMigracionDependeciasSap = async() => {

    try {

        const { data } = await api.post('/revisor/ejecutarMigracionDependenciasSap');
        const { status, message } = data;
        return { status, message };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

};

export const ejecutarMigracionOficinasSap = async() => {

    try {

        const { data } = await api.post('/revisor/ejecutarMigracionOficinasSap');
        const { status, message } = data;
        return { status, message };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

};

export const obtenerCatalogoDependencias = async({ commit }) => {

    try {

        const { data } = await api.get('/revisor/obtenerCatalogoDependencias');
        const { status, data:dataCatalogoDependencias } = data;
        commit("setCatalogoDependencias", dataCatalogoDependencias);
        return { status };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

};

export const saveDependencia = async( _, dataApi) => {

    try {
       
        const { data } = await api.post('/revisor/guardarDependenciaCatalogo', {
            'nombreDependencia': dataApi.nombreDependencia,
            'centralizada': dataApi.centralizada,
            'upp': dataApi.upp
        });

        const { status, message } = data;
        return { status, message };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

};


export const saveOficina = async( _, dataApi) => {

    try {
       
        const { data } = await api.post('/revisor/guardarOficinaCatalogo', {
            'nombreOficina': dataApi.nombreOficina,
            'upp': dataApi.uppDependencia
        });

        const { status, message } = data;
        return { status, message };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

};


export const habilitarDependencia = async( _, dataRequest) => {

    try {
       
        const { data } = await api.post('/revisor/habilitarDependencia', dataRequest);

        const { status, message } = data;
        return { status, message };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

};

export const getRoles = async({ commit }) => {
    try {
        const { data: info } = await api.get("/revisor/obtenerCatalogo/4");
        const { status, data, message } = info;

        if (status != "success") return { ok: false, message };
        commit("setRoles", data);
        return { ok: true, message, data };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

// ------------------------------------------------------ Use administrador y ventanas --------------------------------------------------------- //

export const getUsers = async({ commit }) => {
    try {
        const token = localStorage.getItem("token");
        const { data: info } = await api.get("/revisor/obtenerUsuarios", {
            token,
        });
        const { status, data } = info;

        if (status != "success") return { ok: false };

        commit("setUsers", { users: data });

        return { ok: true };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const updateUser = async(_, user) => {

    try {

        const { data } = await api.post(`/revisor/editarUsuario`, {
            ...user,
        });

        const { status, message } = data;

        return { status, message };

    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const deleteUser = async(_, id) => {

    try {

        const { data } = await api.post(`/revisor/modificarEstadoUsuario`, {
            idUsuario: id
        });

        return data;

    } catch (err) {

        let msg;

        if (err.response) {

            msg = err.response.data.message;

        } else if (err.request) {

            msg = err.request;

        } else {

            msg = err.message;

        }
        return { ok: false, message: msg };

    }
};

export const createUser = async(_, user) => {

    try {
        const { data: info } = await api.post(`/revisor/crearUsuario`, {
            ...user,
        });
        const { status, message } = info;

        return { status, message };

    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const getTecnicos = async({ commit }) => {
    try {

        const { data: info } = await api.get("/revisor/obtenerDireccionesUsuario");
        const { status, data, message } = info;

        if (status != "success") return { ok: false, message };
        commit("setTecnicos", data);

    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

//Metodos para VentanaView
export const getVentanas = async({ commit }) => {
    try {
        const { data: info } = await api.get("/revisor/obtenerVentanasTiempo");
        const { status, data, message } = info;

        if (status != "success") return { ok: false, message };

        commit("setVentanas", data);
        return { ok: true, message };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const createVentana = async(_, ventana) => {
    try {
        const { data: info } = await api.post(`/revisor/crearVentanaTiempo`, {
            ...ventana,
        });
        const { status, message } = info;
        //if (status != "success") return { ok: false, message };

        return { status, message };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const updateWindow = async(_, ventana) => {
    try {

        const { data: info } = await api.post(`/revisor/editarVentanaTiempo`, {
            ...ventana,
        });

        const { status, message } = info;

        return { status, message };

    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};


export const getInfoByCurp = async(_, curp) => {

    try {

        const { data: info } = await api.get(`/revisor/obtenerNombrePorCurp/${curp}`);

        const { status, data: dataReturn } = info;

        return { status, dataReturn };


    } catch (err) {

        let msg;

        if (err.response) {

            msg = err.response.data.message;

        } else if (err.request) {

            msg = err.request;

        } else {

            msg = err.message;
        }

        return { ok: false, message: msg };

    }

}


export const getOficinas = async(_, idDep) => {


    try {

        const { data: info } = await api.get(`/admin/getOficinas/${idDep}`);

        const { status, data: dataReturn } = info;

        return (status == 'success') ? { ok: true, dataReturn } : { ok: false, dataReturn }

    } catch (err) {

        let msg;

        if (err.response) {

            msg = err.response.data.message;

        } else if (err.request) {

            msg = err.request;

        } else {

            msg = err.message;
        }

        return { ok: false, message: msg };

    }


}

export const restablecerContrasenaUsuario = async( _, idUsuario ) => {

    try {

        const { data } = await api.post('/revisor/restablecerContrasenaUsuario',{
            idUsuario 
        });

        const { status, message } = data;
        return { status, message };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const getProyectosRevision = async({ commit }) => {

    try {
        const { data } = await api.get('/revisor/obtenerProyectosConRevisor');

        const { status, data: proyectosRevision } = data;

        if (status == 'success') commit('setProyectosRevision', proyectosRevision);
        return status;

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const getInformacionGeneral = async( _, idDependencia ) => {

    try {
        
        const { data } = await api.get(`/revisor/obtenerInformacionGeneral/${idDependencia}`);
        const { status, dataPA, dataPE, dataProyectos } = data;
         //console.log(data);
        return { status, dataPA, dataPE, dataProyectos };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const enviarEquipoTecnicoProyecto = async( _, idProyecto ) => {

    try {
        
        const { data } = await api.post('/revisor/enviarEquipoTecnicoProyecto',{
            idProyecto 
        });

        const { status, message } = data;
        
        return { status, message };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const getPrimerReporte = async(_, idProyecto ) => {

    try {
        const { data } = await api.get(`/revisor/obtenerPrimerReporteProyecto/${idProyecto}`);

        const { status, message, data:dataReporte } = data;
        
        return { status, message, dataReporte };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};



export const obtenerDocumentosReporte = async( _, { idProyecto, clave, date } ) => {

    try {

        const { data } = await api.get(`revisor/obtenerDocumentosReportes/${idProyecto}/${date}/${clave}`);

        const { status, message, fileBase64  } = data;
        
        return { status, message, fileBase64 };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};


export const getFechasActivas = async(_, planAnual) => {

    try {
     
        const { data } = await api.get(`/revisor/obtenerFechasActivas/${planAnual}`);

        const { status, message, data:info, dataDias  } = data;
        
        return { status, message, info, dataDias };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};

export const setActiveMonths = async(_, { plan_anual, active_months, flag_permisos }) => {

    try {

        const objectData = {
            plan_anual,
            flag_permisos
        }; 

        if( objectData.flag_permisos ) objectData['active_months'] = active_months;

        const { data } = await api.post('/revisor/setActiveMonthsReport', objectData );
        const { status, message } = data;
        
        return { status, message };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};

export const setActiveDays = async(_,  numDias) => {

    try {

        const formData = new FormData();
        formData.set('numDias', numDias);
        const { data } = await api.post('/revisor/setActiveDaysReport', formData);
        const { status, message } = data;
        
        return { status, message };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};

export const getProyectosConResolucion = async({commit}) => {

    try {

        const { data } = await api.get('/revisor/obtenerProyectosConResolucion');
        const { status, data: info } = data;
        if( status == "success" ) commit('setProyectosResolucion', info);
        return { status };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }
};

export const getInfoReports = async( _, idProyecto ) => {

    try {

        const { data } = await api.get(`/revisor/reportes/${idProyecto}`);
        return { data };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

};

export const enviarReporteT1 = async(_, form) => {

    try {
        
        const { data } = await api.post(`revisor/subirReporteT1`, form,  { headers: {
            'Accept': 'application/json',
        }});

        const { status, message } = data;

        return { status, message };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};

export const enviarReporteT2 = async(_, form) => {

    try {
        
        const { data } = await api.post(`revisor/subirReporteT2`, form,  { headers: {
            'Accept': 'application/json',
        }});

        const { status, message } = data;

        return { status, message };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};

export const enviarReporteT3 = async(_, form) => {

    try {
        
        const { data } = await api.post(`revisor/subirReporteT3`, form,  { headers: {
            'Accept': 'application/json',
        }});

        const { status, message } = data;

        return { status, message };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};


export const obtenerFormatosReporte = async(_, claveFormato) => {

    try {

        const { data } = await api.get(`revisor/obtenerFormatosReporte/${claveFormato}`);

        const { status, message, fileBase64  } = data;
        
        return { status, message, fileBase64 };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};

export const obtenerFormatos = async( { commit } ) => {

    try {

        const { data } = await api.get(`revisor/obtenerFormatosDocumentacion`);

        const { status, data:infoData  } = data;
        
        if( status ){
            if( status == "success" )  commit('setFormats', infoData );
        }
        return { status, infoData };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};

export const descargarFormato = async( _, idProyecto ) => {

    try {

        const { data } = await api.get(`revisor/obtenerFormatoPorId/${idProyecto}`);

        const { status, data:infoData, nombreDocumento, fileBase64  } = data;
        
        if( status ){
            //console.log( status )
        }
        return { status, infoData, nombreDocumento, fileBase64 };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};

export const guardarFormato = async(_, formData ) => {

    try {

        const { data } = await api.post(`revisor/crearFormatoDocumentacion`, formData);
        return data

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};

export const actualizarFormato = async(_, formData ) => {

    try {

        const {data} = await api.post(`revisor/actualizarFormatoDocumentacion`, formData);
        //console.log(data, "aqui data")
        return data

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};

export const bajaAltaFormato = async(_, _id ) => {

    try {

        const { data } = await api.post(`revisor/eliminarFormatoDocumentacion`, { _id });
        return data

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};



export const updateProjectDates= async(_, form) => {

    try {

        const { data } = await api.post(`revisor/modificarFechasEjecucion`, form );

        const { status, message } = data;

        return { status, message };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};

export const projectCancellation = async(_, form) => {

    try {

        const { data } = await api.post(`revisor/darBajaProyecto`, form );

        const { status, message } = data;

        return { status, message };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};



export const getDetallesBaja = async(_, _id) => {

    try {

        const { data } = await api.get(`revisor/obtenerDetallesBaja/${_id}`);

        const { status, message, data: responseData, fileBase64  } = data;
        
        return { status, message, responseData, fileBase64 };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};

export const obtenerExpedientes = async( { commit } ) => {

    try {

        const { data } = await api.get(`revisor/obtenerProyectos`);

        const { status, data:infoData  } = data;
        
        if( status ){
            if( status == "success" )  commit('setExpedients', infoData );
        }
        return { status, infoData };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};

export const descargarExpediente = async(_, _id) => {
    try {
        const formData = new FormData();
        formData.append("_id", _id);

        const { data, status } = await api.post(`revisor/obtenerExpediente`, formData, {
            responseType: "blob",
        });
         return { data, status };

    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

