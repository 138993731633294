// export const myAction = async ({commit}) => {

// }

import api from "@/api/laravelApi";

export const getUsers = async({ commit }) => {
    try {
        const token = localStorage.getItem("token");
        const { data: info } = await api.get("/administrador/obtenerUsuarios", {
            token,
        });
        const { status, data } = info;

        if (status != "success") return { ok: false };

        commit("setUsers", { users: data });

        return { ok: true };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const getRoles = async({ commit }) => {
    try {
        const { data: info } = await api.get("/administrador/obtenerCatalogo/4");
        const { status, data, message } = info;

        if (status != "success") return { ok: false, message };
        commit("setRoles", data);
        return { ok: true, message, data };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const updateUser = async(_, user) => {

    try {

        const { data } = await api.post(`/administrador/editarUsuario`, {
            ...user,
        });

        const { status, message } = data;

        return { status, message };

    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const deleteUser = async(_, id) => {

    try {

        const { data } = await api.post(`/administrador/modificarEstadoUsuario`, {
            idUsuario: id
        });

        return data;

    } catch (err) {

        let msg;

        if (err.response) {

            msg = err.response.data.message;

        } else if (err.request) {

            msg = err.request;

        } else {

            msg = err.message;

        }
        return { ok: false, message: msg };

    }
};

export const createUser = async(_, user) => {

    try {
        const { data: info } = await api.post(`/administrador/crearUsuario`, {
            ...user,
        });
        const { status, message } = info;

        return { status, message };

    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

/*export const getAreas = async({ commit }) => {
    try {
        const { data: info } = await api.post("/admin/getAreas");
        const { status, data, message } = info;

        if (status != "success") return { ok: false, message };

        commit("setAreas", data);
        return { ok: true, message, data };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};*/

export const getTecnicos = async({ commit }) => {
    try {

        const { data: info } = await api.get("/administrador/obtenerDireccionesUsuario");
        const { status, data, message } = info;

        if (status != "success") return { ok: false, message };
        commit("setTecnicos", data);

    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

//Metodos para VentanaView
export const getVentanas = async({ commit }) => {
    try {
        const { data: info } = await api.get("/administrador/obtenerVentanasTiempo");
        const { status, data, message } = info;

        if (status != "success") return { ok: false, message };

        commit("setVentanas", data);
        return { ok: true, message };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const createVentana = async(_, ventana) => {
    try {
        const { data: info } = await api.post(`/administrador/crearVentanaTiempo`, {
            ...ventana,
        });
        const { status, message } = info;
        //if (status != "success") return { ok: false, message };

        return { status, message };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const updateWindow = async(_, ventana) => {
    try {

        const { data: info } = await api.post(`/administrador/editarVentanaTiempo`, {
            ...ventana,
        });

        const { status, message } = info;

        if (status != "success") return { ok: false, message };

        return { ok: true, message };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};


export const getInfoByCurp = async(_, curp) => {

    try {

        const { data: info } = await api.get(`/administrador/obtenerNombrePorCurp/${curp}`);

        const { status, data: dataReturn } = info;

        return { status, dataReturn };


    } catch (err) {

        let msg;

        // const arrayError = ( { 0: err.response, 1: err.request } ).filter( true )
        //console.log(objError);

        if (err.response) {

            msg = err.response.data.message;

        } else if (err.request) {

            msg = err.request;

        } else {

            msg = err.message;
        }

        return { ok: false, message: msg };

    }

}

export const getDependencias = async({ commit }) => {

    try {

        const { data } = await api.get(`/administrador/obtenerEntidadesUppUr/1/0`);
        const { status, data: dataReturn } = data;

        if (status == 'success') commit('setDependencias', dataReturn);

    } catch (err) {

        let msg;

        if (err.response) {

            msg = err.response.data.message;

        } else if (err.request) {

            msg = err.request;

        } else {

            msg = err.message;
        }

        return { ok: false, message: msg };

    }

}

export const getOficinas = async(_, idDep) => {


    try {

        const { data: info } = await api.get(`/admin/getOficinas/${idDep}`);

        const { status, data: dataReturn } = info;

        return (status == 'success') ? { ok: true, dataReturn } : { ok: false, dataReturn }

    } catch (err) {

        let msg;

        if (err.response) {

            msg = err.response.data.message;

        } else if (err.request) {

            msg = err.request;

        } else {

            msg = err.message;
        }

        return { ok: false, message: msg };

    }


}

export const getAllProjectsByStatus = async() => {

    try {

        const { data } = await api.post('/general/getProjectsChart');

        const { status, data: dataResponse } = data;

        return (status === 'success') ? { ok: true, data: dataResponse } : { ok: false, data: {} }

    } catch (err) {

        let msg;

        if (err.response) {

            msg = err.response.data.message;

        } else if (err.request) {

            msg = err.request;

        } else {

            msg = err.message;
        }

        return { ok: false, message: msg };

    }

}


export const restablecerContrasenaUsuario = async( _, idUsuario ) => {

    try {

        const { data } = await api.post('/administrador/restablecerContrasenaUsuario',{
            idUsuario 
        });

        const { status, message } = data;
        return { status, message };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

