import { createRouter, createWebHashHistory } from "vue-router";
import NoPageFound from "@/views/NoPageFoundView.vue";
import isAuthenticatedGuard from "@/modules/auth/router/auth-guard";
/*Modulo Administrador*/
import adminRouter from "../modules/admin/router";

/*Modulo Dependencia*/
import DependenciaPrincipal from "../modules/dependencia/views/DepPrincipalView";
import DependenciaRouter from "../modules/dependencia/router/index";

/*Modulo Consejo*/
import ConsejoPrincipal from "../modules/consejo/views/ProyectosConsejo";
import ConsejoRouter from "../modules/consejo/router/index";

/*Modulo Mesa de Ayuda*/
import MesaAyudaPrincipal from "../modules/mesa/views/RecepcionProyectosMesa";
import MesaAyudaRouter from "../modules/mesa/router/index";

/*Modulo Direcciones/Tecnico*/
import DireccionesTecnicoPrincipal from "../modules/direccion/views/SeguimientoProyectos.vue";
import DireccionesTecnicoRouter from "../modules/direccion/router/index";

const routes = [
  {
    path: "/",
    name: "login",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "@/modules/auth/views/LoginView.vue"
      ),
  },
  {
    path: "/admin",
    beforeEnter: [isAuthenticatedGuard],
    ...adminRouter,
  },

  {
    path: "/dependencia",
    component: DependenciaPrincipal,
    beforeEnter: [isAuthenticatedGuard],
    ...DependenciaRouter,
  },

  {
    path: "/consejo",
    component: ConsejoPrincipal,
    beforeEnter: [isAuthenticatedGuard],
    ...ConsejoRouter,
  },

  {
    path: "/revisor",
    component: MesaAyudaPrincipal,
    beforeEnter: [isAuthenticatedGuard],
    ...MesaAyudaRouter,
  },

  {
    path: "/direcciones",
    component: DireccionesTecnicoPrincipal,
    beforeEnter: [isAuthenticatedGuard],
    ...DireccionesTecnicoRouter,
  },

  {
    path: "/:pathMatch(.*)*",
    component: NoPageFound,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
