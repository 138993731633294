import { ref } from "vue";

const dialog = ref({
    role: [],
});

const projectId = ref(null);
const project_name = ref(null);
const projectFolio = ref(null);
const _idProyecto = ref(null);
const upp = ref(null);
const nombreDependencia = ref(null);
const seccion = ref(null);
const planAnual = ref(null);

const useOpenDialogs = () => {

    const toggleDialog = () => {

        dialog.value.role.pop();
        projectId.value = null;
        project_name.value = null;
        projectFolio.value = null;
        nombreDependencia.value = null;
        seccion.value = null;
    };

    const openDialog = (role = "", project, id, name, _id, uppDependencia, nombreDependenciaSelected = null, seccionFechas = null, planAnualProject = null ) => {
        projectId.value = project;
        project_name.value = name;
        projectFolio.value = id;
        _idProyecto.value = _id;
        upp.value = uppDependencia.toString().padStart(3, "0");
        dialog.value.role.push({ type: role, isOpen: true });
        nombreDependencia.value = nombreDependenciaSelected;
        seccion.value = seccionFechas;
        planAnual.value = planAnualProject;
    };

    const hasRole = (role = "") => {

        if (role === "") return false;

        const findRole = dialog.value.role.find((currentRole) =>
            currentRole.type === "" ? null : currentRole.type === role
        );

        if (findRole === undefined) return false;

        return findRole.type === role && findRole.isOpen === true ? true : false;

    };

    return {
        toggleDialog,
        openDialog,
        hasRole,
        projectId,
        project_name,
        projectFolio,
        _idProyecto,
        upp,
        nombreDependencia,
        seccion,
        planAnual
    };
};

export default useOpenDialogs;