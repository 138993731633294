import router from "@/router";
import axios from "axios";
import Swal from "sweetalert2";

const journalApi = axios.create({
    baseURL: process.env.VUE_APP_URL_API,
});

const responseHandler = ( response ) => {
    return response;
}

const errorHandler = ( error ) =>{

    if( error.response ){

        if( error.request.status === 401 || error.request.status === 403 ){

            localStorage.clear();
            router.push({name: "login"});

            Swal.fire({
                icon: 'info',
                iconColor:  "#6a0f49",
                title: 'Tu sesión expiró',
                text: 'Tu sesión ha expirado, vuelve a acceder al sistema ingresando tus credenciales',
                showConfirmButton: false,
                timer: 3500
            });

        }else if( error.request.status === 404 ){
            
            return { status: 404, message: 'Recurso no encontrado'};

        }else if( error.request.status === 413 ){

            Swal.fire({
                icon: 'info',
                iconColor:  "#6a0f49",
                title: 'Información',
                text: 'Tamaño de archivos excedente a lo permitido',
                showConfirmButton: false,
                timer: 3500
            });

        }else if( error.request.status === 422 ){
            // showMessageErrors();
            return Promise.reject(error);

        }else if( error.request.status === 500 ){

            return { data: { status: 'error', message: 'Ha ocurrido un error'} };

        }
    }
}
/* const showMessageErrors = () => {
    Swal.fire({
        icon: 'error',
        iconColor:  "#6a0f49",
        title: 'Error',
        text: 'Valide la información y/o documentos',
        showConfirmButton: false,
        timer: 3500
    });
} */
 

journalApi.interceptors.request.use((config) => {
   
    config.headers = {
        authorization: `Bearer ${localStorage.getItem("token")}`,
        'Accept': 'application/json',
    };
    return config;
});

journalApi.interceptors.response.use(
    (response) => responseHandler(response), 
    (error) => errorHandler(error), 
);

export default journalApi;