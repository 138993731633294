import isAuthenticatedGuard from "@/modules/auth/router/auth-guard";
import isRolMesaGuard from "@/modules/mesa/router/mesa-rol-guard";
import store from "@/store";

export default {
  
  beforeEnter: [isAuthenticatedGuard, isRolMesaGuard],
  component: () => import("@/layouts/MainLayout.vue"),
  props: () => {
    
    const arrayItems = [
      /*{
        label: "Dashboard",
        icon: "fa-solid fa-chart-column",
        to: "/revisor/dashboard",
      },*/
      {
        label: "Convenio Dependencias",
        icon: "fa-regular fa-handshake",
        to: "/revisor/convenio-dependencias",
      }, 
      {
        label: "Apertura Reporteo",
        icon: "fa-solid fa-calendar",
        to: "/revisor/apertura-reporteo",
      }, 
      {
        label: "Ventanillas",
        icon: "fa-solid fa-clock",
        to: "/revisor/ventana-tiempo",
      }, 
      {
        label: "Recepción de Proyectos",
        icon: "fa-solid fa-folder-open",
        to: "/revisor",
      },
      {
        label: "Revisión de Proyectos",
        icon: "fa-solid fa-file-signature",
        to: "/revisor/revision-proyectos",
      },
      {
        label: "Dependencias",
        icon: "fa-solid fa-shield",
        to: "/revisor/registro-dependencias",
      },
      {
        label: "Usuarios",
        icon: "fa-solid fa-user",
        to: "/revisor/usuarios",
      },
      {
        label: "Formatos",
        icon: "fa-solid fa-folder",
        to: "/revisor/formatos",
      },
      {
        label: "Expedientes",
        icon: "fa-solid fa-folder-open",
        to: "/revisor/expedientes",
      },
      /*{
        label: "Estado Información",
        icon: "fa-solid fa-circle-info",
        to: "/revisor/estado-informacion",
      },*/
    ];

    //Validaciones permisos
    if(store.state.auth.user.permisos[0]) arrayItems.push({ label: "Asignación Revisores", icon: "fa-solid fa-users", to: "/revisor/asignacion-revisores"});
    if(store.state.auth.user.permisos[1]) arrayItems.push({ label: "Analisis Información", icon: "fa-solid fa-list-check",  to: "/revisor/gestion-revision"});
    if(store.state.auth.user.permisos[2]) arrayItems.push({ label: "Seguimiento", icon: "fa fa-eye",  to: "/revisor/seguimiento"});
   
    return {

      menuItems: arrayItems
      
    };
  },
  children: [
    {
      path: "",
      beforeEnter: [isAuthenticatedGuard, isRolMesaGuard],
      component: () =>
        import("@/modules/mesa/views/RecepcionProyectosMesa.vue"),
    },
    {
      path: "revision-proyectos",
      beforeEnter: [isAuthenticatedGuard, isRolMesaGuard],
      component: () => import("@/modules/mesa/views/RevisionProyectosMesa.vue"),
    },
    {
      path: "solicitud-dictamen",
      beforeEnter: [isAuthenticatedGuard, isRolMesaGuard],
      component: () => import("@/modules/mesa/views/SolicitudDictamenMesa.vue"),
    },
    {
      path: "revisionproyectosmesadocs",
      beforeEnter: [isAuthenticatedGuard, isRolMesaGuard],
      component: () => import("@/modules/mesa/views/ProyectosMesaRevDocs.vue"),
    },
    {
      path: "convenio-dependencias",
      beforeEnter: [isAuthenticatedGuard, isRolMesaGuard],
      component: () => import("@/modules/mesa/views/ConvenioDependencias.vue"),
    },
    {
      path: "apertura-reporteo",
      beforeEnter: [isAuthenticatedGuard, isRolMesaGuard],
      component: () => import("@/modules/mesa/views/AperturaReportesView.vue"),
    },
    {
      path: "asignacion-revisores",
      beforeEnter: [isAuthenticatedGuard, isRolMesaGuard],
      component: () => import("@/modules/mesa/views/AsignacionRevisores.vue"),
    },
    {
      path: "registro-dependencias",
      beforeEnter: [isAuthenticatedGuard, isRolMesaGuard],
      component: () => import("@/modules/mesa/views/RegistroDependencia.vue"),
    },
    {
      path: "usuarios",
      beforeEnter: [isAuthenticatedGuard, isRolMesaGuard],
      component: () => import("@/modules/mesa/views/AccesosView.vue"),
    },
    {
      path: "gestion-revision",
      beforeEnter: [isAuthenticatedGuard, isRolMesaGuard],
      component: () => import("@/modules/mesa/views/ProyectosRevisionView.vue"),
    },
    {
      path: "estado-informacion",
      beforeEnter: [isAuthenticatedGuard, isRolMesaGuard],
      component: () => import("@/modules/mesa/views/EstadoInformacionView.vue"),
    },
    {
      path: "ventana-tiempo",
      beforeEnter: [isAuthenticatedGuard, isRolMesaGuard],
      component: () => import("@/modules/mesa/views/VentanaView.vue"),
    },
    {
      path: "seguimiento",
      beforeEnter: [isAuthenticatedGuard, isRolMesaGuard],
      component: () => import("@/modules/mesa/views/SeguimientoView.vue"),
    },
    {
      path: "formatos",
      beforeEnter: [isAuthenticatedGuard, isRolMesaGuard],
      component: () =>
        import("@/modules/mesa/views/ModuloFormatosView.vue"),
    },
    {
      path: "expedientes",
      beforeEnter: [isAuthenticatedGuard, isRolMesaGuard],
      component: () =>
        import("@/modules/mesa/views/ModuloExpedientesView.vue"),
    },

  ],
};
