// export const myGetter = (state) => {
// return state
// }

export const getRequestDictamenProjects = (state) => {
    return state.request_dictamen_projects;
};

export const getProyectsDictaminados = (state) => {
    return state.estado_dictaminados_projects;
};

export const getAceptedProjects = (state) => {
    return state.aceptedProjects;
};

export const getSuggestedDates = (state) => {
    return state.suggestedDates;
};

export const getNumProjectsAcccepted = (state) => {
    return state.numProjectsAcccepted;
};

export const getReportsIndex = (state) => {
    return state.reportsIndex;;
};