import isAuthenticatedGuard from "@/modules/auth/router/auth-guard";
import isRolConsejoGuard from "@/modules/consejo/router/consejo-rol-guard";
export default {
  beforeEnter: [isAuthenticatedGuard, isRolConsejoGuard],
  component: () => import("@/layouts/MainLayout.vue"),
  props: () => {
    return {
      menuItems: [
        {
          label: "Recepción de Proyectos",
          icon: "fa-solid fa-folder-open",
          to: "/consejo/",
        },
        {
          label: "Seguimiento de Proyectos",
          icon: "fa-solid fa fa-clipboard",
          to: "/consejo/proyectos-resolucion",
        },
        {
          label: "Carga de Archivos Resolución",
          icon: "fa-solid fa fa-upload",
          to: "/consejo/carga-archivo",
        }
      ],
    };
  },
  children: [
    {
      path: "",
      beforeEnter: [isAuthenticatedGuard, isRolConsejoGuard],
      component: () => import("@/modules/consejo/views/ProyectosConsejo.vue"),
    },
    {
      path: "proyectos-resolucion",
      beforeEnter: [isAuthenticatedGuard, isRolConsejoGuard],
      component: () =>
      import("@/modules/consejo/views/ProyectosResolucion.vue"),
    },
    {
      path: "carga-archivo",
      beforeEnter: [isAuthenticatedGuard, isRolConsejoGuard],
      component: () =>
      import("@/modules/consejo/views/ProyectosRespuesta.vue"),
    },
    //     {
    //       path: ":id",
    //       name: "entry",
    //       component: () => import("@/modules/daybook/views/EntryView.vue"),
    //       props: (route) => {
    //         const id = Number(route.params.id);
    //         return {
    //           id: isNaN(id) ? 1 : id,
    //         };
    //       },
    //     },
  ],
};
