<template>
  <ToolbarVue class="mb-4">
    <template #start>
        <div class="col-12">
          <h5 class="mb-2 md:m-0 p-as-md-center">
            Revisor
            <i class="pi pi-users px-" style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i> |
            Recepción de proyectos
            <i class="fa fa-folder-open px-1" style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
          </h5>
        </div>
      </template>
  </ToolbarVue>
</template>