import { computed, ref, reactive } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";

import useOpenDialogs from "./useOpenDialogs";
import { FilterMatchMode, FilterOperator } from "primevue/api";

const useProyectosConsejoList = () => {
    const store = useStore();
    const { toggleDialog } = useOpenDialogs();

    const proyectoEstado = ref({});
    const project = ref({});
    const tecnicos = ref([]);
    const tecnico = ref(1);
    const send = ref(false);
    const reject = ref(false);
    const motivosCancelado = ref();
    const reasignacion = ref(null);
    const catAsignaciones = ref([]);
    const actionConsejo = ref(null);
    const direccion = ref(null);
    const comentarioResolucionEquipoTecnico = ref(null);
    const archivoResolucionEquipoTecnico = ref(null);
    const error = ref(false);
    const asignacion = ref(null);
    const usuarioDireccion = ref([]);
    const usuariosDireccion = ref(null);
    let optionsSeguimiento = ref(null);
    const planesAnuales = ref(null);
    const selectedResolucion = ref(3);
    const fecha_inicio_asignada = ref(null);
    const fecha_fin_asignada = ref(null);
    const flagCloseDialog = ref(null);
    const sendFlag = ref(false);
    const tipoProyecto = ref(null);
    const sendValidationParams = reactive({
        accion: null,
        direccion: null,
        personaAsignacion: null,
        reasignacion: null,
        seguimiento: null,
        personaSeguimiento: null
    });


    const columns = ref([
        { field: "folio", header: "Folio" },
        { field: "seccion.1.nombre", header: "Nombre" },
        { field: "dependencia", header: "Dependencia" },
        // { field: "estado", header: "Estatus" },
        { field: "fecha_creacion", header: "Fecha Creación" },
        { field: "_id", header: "id_proyecto", hidden: true },
        { field: "dependencia_id", header: " dependencia_id", hidden: true },
        { field: "seccion.3", header: " fechas sugeridas", hidden: true },

    ]);

    const columnsAllProjects = ref([
        { field: "folio", header: "Folio" },
        { field: "seccion.1.nombre", header: "Nombre" },
        { field: "dependencia", header: "Dependencia" },
        // { field: "estado", header: "Estatus" },
        { field: "_id", header: "id_proyecto", hidden: true },
        { field: "dependencia_id", header: " dependencia_id", hidden: true },
    ]);

    const filters1 = ref({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        project_folio: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        project_name: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        description: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        nombre_area: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        status: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        updated_at: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
    });

    const uploadRespuestaProject = async(data) => {

        const formData = new FormData();
        formData.append(data.clave, data.archivo);
        formData.append('id_proyecto', data.id_proyecto);
        formData.append('id_dependencia', data.id_dependencia);

        const resp = await store.dispatch("consejo/uploadRespuestaProject", formData);
        return resp;
    }

    const getProyectosList = async() => {
        const resp = await store.dispatch("consejo/getProyectosList");
        return resp;
    };

    const getAllProyectosList = async() => {
        const resp = await store.dispatch("consejo/getProyectosRevision");
        return resp;
    };

    const getProject = async(id) => {
        const resp = await store.dispatch("consejo/getProject", id);
        return resp;
    };

    const getCatAsignaciones = async() => {
        const { ok, message, data } = await store.dispatch(
            "consejo/getCatAsignaciones"
        );
        if (!ok) {
            Swal.fire("Error", message, "error");
        } else {
            catAsignaciones.value = data;
        }
    };

    const rejectProject = async(projectId) => {
        reject.value = true;
        error.value = false;
        if (!motivosCancelado.value) error.value = true;

        if (!error.value && motivosCancelado.value.trim() != "") {
            const form = {
                id_project: projectId,
                assigned_to: reasignacion.value,
                motivo: motivosCancelado.value,
            };
            const { ok, message } = await store.dispatch("consejo/reasignarProject", {
                form,
            });
            if (!ok) {
                toggleDialog();
                Swal.fire("Error", message, "error");
            } else {
                getProyectosList();
                toggleDialog();
                Swal.fire("Éxito", "Proyecto reasignado correctamente", "success");
            }
        } else {
            error.value = true;
        }

        reject.value = false;
    };

    const downloadZip = async(id) => {
        new Swal({
            title: "Obteniendo archivos...",
            allowOutsideClick: false,
        });
        Swal.showLoading();
        const { ok, message, data } = await store.dispatch(
            "consejo/downloadZip",
            id
        );
        if (!ok) {
            Swal.fire("Error", message, "error");
        } else {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "carpeta.zip");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            Swal.close();
        }
    };


    //---------------------------------------------------------------------------- Nuevo -----------------------------------------------------------------------------------------//

    const getCatalogos = async() => {

        await store.dispatch("consejo/getCatalogos");

    }

    const getUsuariosDireccion = async(idDireccion) => {

        const { dataResponse, status } = await store.dispatch("consejo/getUsuariosDirección", idDireccion);
        if (status == 'success') usuariosDireccion.value = dataResponse;
        if (status == 'info') {
            usuariosDireccion.value = [];
            usuarioDireccion.value = [];
        }

    }


    const getOptionsReasignacion = (option) => {

        optionsSeguimiento.value = [];

        if (option != 3) optionsSeguimiento.value.push({ 'id': 0, 'asignacion': 'La propia dependencia' },
                                                       { 'id': 1, 'asignacion': 'Dirección de Gobierno digital' },
                                                       { 'id': 2, 'asignacion': 'Dirección de Infraestructura' }, 
                                                       { 'id': 3, 'asignacion': 'Dirección de Normatividad' }, 
                                                       { 'id': 4, 'asignacion': 'Dirección de vinculación' });

    }


    const sendResolucion = async(data) => {

        const arrayValidate = [];
        sendFlag.value = true;

        if (actionConsejo.value == null) {
            //showAlert('info', 'Información', 'Debes llenar todos los campos obligatorios');
            return false;
        }

        switch (actionConsejo.value) {

            case 1:
                if (direccion.value == null || usuarioDireccion.value.length == 0 || reasignacion.value == null || tipoProyecto.value == null ) arrayValidate.push(1);
                break;
            case 2:
                if (reasignacion.value != null){

                    switch( reasignacion.value ){

                        case 5:
                        case 6:
                            if( asignacion.value == null ) arrayValidate.push(1);
                            if( asignacion.value != 0 ){
                                if(usuarioDireccion.value.length == 0) arrayValidate.push(1);
                            } 
                            break;

                    }

                }else{
                   arrayValidate.push(1); /*|| usuarioDireccion.value.length == 0 ) arrayValidate.push(1);*/
                } 
                break;

        }

        if (arrayValidate.includes(1)) {
            //showAlert('info', 'Información', 'Debes llenar todos los campos obligatorios');
            return false;

        } else {

            Swal.fire({

                text: `¿Estas seguro que deseas realizar esta acción?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                confirmButtonColor: "#6a0f49",
                cancelButtonColor: "#ec85c2",
    
            }).then(async(result) => {

                if (result.isConfirmed) {

                    const { status, message } = await store.dispatch("consejo/sendValidationConsejo", data);

                    await showAlert(status, 'Información', message);

                    if (status == 'success') {

                        await getProyectosList();
                        await toggleDialog();

                    }

                    return status;

                }
    
            });

        }



    }

    const showAlert = (icon, title, text) => {

        Swal.fire({
            icon: icon,
            title: title,
            text: text,
            showConfirmButton: false,
            timer: 2800
        });

    }

    const getInputsForm = async(idFormulario) => {
        const resp = await store.dispatch("consejo/getInputsForm", idFormulario);
        return resp;
    };

    const getPlanesAnuales = async() => {

        await store.dispatch("consejo/getPlanesAnuales");

    }

    return {

        getProject,
        getProyectosList,
        getAllProyectosList,
        catalogoDirecciones: computed(() => store.getters["consejo/getCatalogoDirecciones"]),
        catalogoAsignaciones: computed(() => store.getters["consejo/getCatalogoEjecucionProyecto"]),
        getAllInputsForm: computed(() => store.getters["consejo/getAllInputsForm"]),
        catalogoTiposProyecto: computed(() => store.getters["consejo/getCatalogoTiposProyecto"]),
        filters1,
        columns,
        proyectoEstado,
        project,
        proyectState: computed(() => store.getters["consejo/getProyectosResolucion"]),
        allProyectsState: computed(() => store.getters["consejo/getAllProyectosList"]),
        tecnico,
        tecnicos,
        send,
        Swal,
        rejectProject,
        reject,
        motivosCancelado,
        reasignacion,
        getCatAsignaciones,
        catAsignaciones,
        error,
        downloadZip,

        //--Nuevo--//
        getCatalogos,
        actionConsejo,
        direccion,
        asignacion,
        getUsuariosDireccion,
        usuarioDireccion,
        usuariosDireccion,
        optionsSeguimiento,
        getOptionsReasignacion,
        sendResolucion,
        sendValidationParams,
        getInputsForm,
        getPlanesAnuales,
        optionsPlanesAnuales: computed(
            () => store.getters["consejo/getPlanesAnuales"]
        ),
        planesAnuales,
        selectedResolucion,
        comentarioResolucionEquipoTecnico,
        archivoResolucionEquipoTecnico,
        fecha_inicio_asignada,
        fecha_fin_asignada,
        flagCloseDialog,
        sendFlag,
        columnsAllProjects,
        uploadRespuestaProject,
        catalogoAsignacion: computed(
            () => store.getters["consejo/getCatalogoAsignacionResolucion"]
        ),
        tipoProyecto
    };
};

export default useProyectosConsejoList;