import { createApp } from "vue";
import App from "./App.vue";
import VueParticles from "vue-particles";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "@/styles/style.scss";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.min.css";
import "bootstrap/dist/css/bootstrap.css";

import "primeicons/primeicons.css";
import router from "./router";
import store from "./store";

//Framework
import PrimeVue from "primevue/config";
import "primevue/resources/themes/tailwind-light/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "/node_modules/primeflex/primeflex.css"; //flexbox
//Componentes import
import Toolbar from "primevue/toolbar";
import Button from "primevue/button";
import SplitButton from "primevue/splitbutton";
import Sidebar from "primevue/sidebar";
import Dock from "primevue/dock";
import TieredMenu from "primevue/tieredmenu";
import Panel from "primevue/panel";
import Avatar from "primevue/avatar";
import AvatarGroup from "primevue/avatargroup";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup"; //optional for column grouping
import Row from "primevue/row";
import Card from "primevue/card";
import Textarea from "primevue/textarea";
import InputText from "primevue/inputtext"; //optional for row
import Tooltip from "primevue/tooltip";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import Dialog from "primevue/dialog";
import Chip from "primevue/chip";
import Checkbox from "primevue/checkbox";
import Dropdown from "primevue/dropdown";
import Rating from "primevue/rating";
import FileUpload from "primevue/fileupload";
import Calendar from "primevue/calendar";
import Slider from "primevue/slider";
import ProgressBar from "primevue/progressbar";
import Tag from "primevue/tag";
import Message from 'primevue/message';
import InlineMessage from 'primevue/inlinemessage';
import InputNumber from 'primevue/inputnumber';
import Skeleton from 'primevue/skeleton';
const app = createApp(App);

app.use(store);
app.use(router);
app.use(VueParticles);
app.use(PrimeVue, {
    locale: {
        choose: "Elegir Archivo",
        upload: "Subir",
        cancel: "Cancelar",
        dayNames: [
            "Domingo",
            "Lunes",
            "Martes",
            "Miercoles",
            "Jueves",
            "Viernes",
            "Sabado",
        ],
        dayNamesShort: ["Dom", "Lun", "Mar", "Mir", "Jue", "Vie", "Sab"],
        dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
        monthNames: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septimbre",
            "Octubre",
            "Noviembre",
            "Diciembre",
        ],
        monthNamesShort: [
            "Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Ago",
            "Sep",
            "Oct",
            "Nov",
            "Dic",
        ],
        today: "Today",
        weekHeader: "Wk",
        firstDayOfWeek: 0,
        dateFormat: "yy-mm-dd",
        weak: "Débil",
        medium: "Media",
        strong: "Fuerte",
    },
});

app.component("SkeletonVue", Skeleton);
app.component("ToolbarVue", Toolbar);
app.component("ButtonVue", Button);
app.component("SplitButtonVue", SplitButton);
app.component("SidebarVue", Sidebar);
app.component("DockVue", Dock);
app.component("TieredMenuVue", TieredMenu);
app.component("PanelVue", Panel);
app.component("AvatarVue", Avatar);
app.component("AvatarGroupVue", AvatarGroup);
app.component("DataTableVue", DataTable);
app.component("ColumnVue", Column);
app.component("ColumnGroupVue", ColumnGroup);
app.component("RowVue", Row);
app.component("CardVue", Card);
app.directive("tooltip", Tooltip);
app.use(ConfirmationService);
app.use(ToastService);
app.component("TextareaV", Textarea);
app.component("InputTextV", InputText);
app.component("DialogVue", Dialog);
app.component("ChipVue", Chip);
app.component("CheckboxVue", Checkbox);
app.component("DropdownVue", Dropdown);
app.component("RatingVue", Rating);
app.component("FileUploadVue", FileUpload);
app.component("CalendarVue", Calendar);
app.component("SliderVue", Slider);
app.component("ProgressBarVue", ProgressBar);
app.component("TagVue", Tag);
app.component("MessageVue",Message);
app.component("InlineMessageVue",InlineMessage);
app.component("InputNumberVue",InputNumber);


app.mount("#app");