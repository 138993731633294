import isAuthenticatedGuard from "@/modules/auth/router/auth-guard";
import isRolDepGuard from "@/modules/dependencia/router/dep-rol-guard";
export default {
    beforeEnter: [isAuthenticatedGuard, isRolDepGuard],
    component: () =>
        import ("@/layouts/MainLayout.vue"),
    props: () => {
        return {
            menuItems: [
                /*{
                    label: "Dashboard",
                    icon: "fa-solid fa-chart-column",
                    to: "/dependencia",
                },
                */{
                    label: "Convenio DGGD",
                    icon: "fa-regular fa-handshake",
                    to: "/dependencia/conveniodggd",
                },
                {
                    label: "Plan Estratégico",
                    icon: "fa-solid  fa-cubes",
                    to: "/dependencia",
                },
                {
                    label: "Plan Anual",
                    icon: "fa-solid  fa-clipboard-list",
                    to: "/dependencia/plan-anual",
                },
                {
                    label: "Proyectos",
                    icon: "fa-solid fa-folder-plus",
                    to: "/dependencia/proyectos",
                },
                {
                    label: "Seguimiento",
                    icon: "fa-solid fa-folder-tree",
                    to: "/dependencia/admon-proyectos",
                },
                {
                    label: "Formatos",
                    icon: "fa fa-folder",
                    to: "/dependencia/formatos",
                },
                {
                    label: "Plataforma",
                    icon: "fa-solid fa-circle-question",
                    to: "/dependencia/recursos-plataforma",
                },
            ],
        };
    },
    children: [
        /*{
            path: "",
            beforeEnter: [isAuthenticatedGuard, isRolDepGuard],
            component: () =>
                import ("@/modules/dependencia/views/DepPrincipalView.vue"),
        },*/
        {
            path: "conveniodggd",
            beforeEnter: [isAuthenticatedGuard, isRolDepGuard],
            component: () =>
                import ("@/modules/dependencia/views/ConvenioDGGDView.vue"),
        },
        {
            path: "plan-anual",
            beforeEnter: [isAuthenticatedGuard, isRolDepGuard],
            component: () =>
                import ("@/modules/dependencia/views/PlanAnualView"),
        },
        {
            path: "proyectos",
            beforeEnter: [isAuthenticatedGuard, isRolDepGuard],
            component: () =>
                import ("@/modules/dependencia/views/ProyectosView"),
        },
        {
            path: "admon-proyectos",
            beforeEnter: [isAuthenticatedGuard, isRolDepGuard],
            component: () =>
                import ("@/modules/dependencia/views/AdminProyView"),
        },
        {
            path: "recursos-plataforma",
            beforeEnter: [isAuthenticatedGuard, isRolDepGuard],
            component: () =>
                import ("@/modules/dependencia/views/RecursosPlataformaView"),
        },
        {
            path: "",
            beforeEnter: [isAuthenticatedGuard, isRolDepGuard],
            component: () =>
                import ("@/modules/dependencia/views/PlanEstrategicoView"),
        },
        {
            path: "formatos",
            beforeEnter: [isAuthenticatedGuard, isRolDepGuard],
            component: () =>
                import ("@/modules/dependencia/views/ModuloFormatosView"),
        },
        /* {
            path: "plan-estrategico",
            beforeEnter: [isAuthenticatedGuard, isRolDepGuard],
            component: () =>
                import ("@/modules/dependencia/views/PlanEstrategicoFormView"),
        }, */
    ],
};