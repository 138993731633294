export default () => ({
    estado: null,
    allProyects: null,
    project: null,
    inputs: null,
    catalogoDirecciones: null,
    catalogoAsignacionResolucion: null,
    planesAnuales: null,
    oficinas: null,
    catalogoImpactosProyecto: null,
    catalogoTiposProyecto: null,
    catalogoPoblacionObjetivo: null,
    catalogoEjecucionProyecto: null,
    catalogoBrindaraServicio: null,
    catalogoPrioridad: null,
    inputsPA: null,
    inputsPE: null,
    catalogoTipoUsuario: null,
    catalogoAsignacion: null,
    catalogoDireccion: null,
    proyectosResolucion: []
});