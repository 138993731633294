<template>
    <ToolbarVue class="mb-4">
        <template #start>
            <div class="col-12">
            <h5 class="mb-2 md:m-0 p-as-md-center">
                Dirección
                <i class="fa fa-shield px-1" style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i> |
                Seguimiento
                <i class="pi pi-chart-bar px-1" style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
            </h5>
            </div>
        </template>
    </ToolbarVue>
<!--<DataTableVue
        :value="getRequestDictamenProjects"
        stripedRows
        :paginator="true"
        :rows="10"
        paginatorTemplate="CurrentPageReport  PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 20, 30]"
        responsiveLayout="scroll"
        removableSort
        dataKey="project_folio"
        v-model:filters="filters1"
        :scrollable="true"
        scrollHeight="flex"
        class="p-datatable-sm"
        style="width: 100%"
    >
    <template #header>
      <div class="flex flex-column md:flex-row md:justiify-content-between">
        <div class="col flex justify-content-end md:justiify-content-center">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputTextV
              v-model="filters1['global'].value"
              placeholder="Buscar"
            />
          </span>
        </div>
      </div>
    </template>
    <template #empty> No se encontraron resultados. </template>
    <ColumnVue
      v-for="col of columns"
      :field="col.field"
      :header="col.header"
      :key="col.field"
      :sortable="true"
      :hidden="col.hidden"
    >
    </ColumnVue>
    <ColumnVue :exportable="false" style="min-width: 8rem" header="Opciones">
      <template #body="slotProps">
        <SplitButtonVue
          v-if="slotProps.data.status_project == 7"
          label="Ver"
          :model="[
            {
              label: 'Ver Ficha',
              icon: 'pi pi-eye',
              command: () => {
                openDialog(
                  'VerFicha',
                  slotProps.data.project_folio,
                  slotProps.data.id
                );
              },
            },
            {
              label: 'Ver Adjuntos',
              icon: 'pi pi-file',
              command: () => {
                openDialog(
                  'VerFiles',
                  slotProps.data.project_folio,
                  slotProps.data.id
                );
              },
            },
          ]"
          class="p-button-sm mb-2"
        />
        <SplitButtonVue
          v-if="slotProps.data.status_project == 8"
          label="Ver"
          :model="[
            {
              label: 'Ver Ficha',
              icon: 'pi pi-eye',
              command: () => {
                openDialog(
                  'VerFicha',
                  slotProps.data.project_folio,
                  slotProps.data.id
                );
              },
            },
            {
              label: 'Ver Adjuntos',
              icon: 'pi pi-file',
              command: () => {
                openDialog(
                  'VerFilesConDictamen',
                  slotProps.data.project_folio,
                  slotProps.data.id
                );
              },
            },
            {
              label: 'Envíar a Mesa de Ayuda',
              icon: 'pi pi-send',
              command: () => {
                openDialog(
                  'SendToMA',
                  slotProps.data.project_folio,
                  slotProps.data.id
                );
              },
            },
          ]"
          class="p-button-sm mb-2"
        />
      </template>
    </ColumnVue>
  </DataTableVue>-->
</template>
<script>
import useSeguimiento from '../composables/Seguimiento/useSeguimiento.js';
import { onMounted } from "vue";

export default {

    components: {
    
    },

    setup(){

        const {
            getProyectosConResolucion
        } = useSeguimiento();

        onMounted(()=>{
            getProyectosConResolucion();
        })

        return {
        }
    },
};
</script>