import store from "@/store";

const isConsejoGuard = (to, from, next) => {
    const arrayUserType = [
        "admin",
        "dependencia",
        "revisor",
        "direcciones",
        "consejo",
        //"direcciones",
    ];

    if (store.state.auth.user.tipo_usuario.id === 5) next();
    else next(`/${arrayUserType[store.state.auth.user.tipo_usuario.id - 1]}`);
};

export default isConsejoGuard;