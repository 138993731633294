import store from "@/store";

const isAdminGuard = (to, from, next) => {
    const arrayUserType = [
        "admin",
        "dependencia",
        "revisor",
        "consejo",
        "direcciones",
    ];
    if (store.state.auth.user.tipo_usuario.id === 1) next();
    else next(`/${arrayUserType[store.state.auth.user.tipo_usuario.id - 1]}`);
    //else next(`/${arrayUserType[store.state.auth.user.tipo_user - 1]}`);
};

export default isAdminGuard;