export default () => ({
  projects_reception: null,
  projects_revision: null,
  projects_request: null,
  project: null,
  inputs: null,
  convenios: null,
  planesDependencia: null,
  catalogoImpactosProyecto: null,
  catalogoTiposProyecto: null,
  catalogoPoblacionObjetivo: null,
  catalogoEjecucionProyecto: null,
  catalogoBrindaraServicio: null,
  catalogoPrioridad: null,
  oficinas: null,
  inputsPA: null,
  inputsPE: null,
  proyectos: null,
  inventarioProyectos: null,
  planesAnuales: null,
  revisores: null,
  proyectosRevisores: null,
  catalogoDependencias: null,
  proyectosRevision: null,
  //Admin de usuarios y ventanas
  users: null,
  roles: null,
  areas: null,
  ventanas: null,
  tecnicos: null,
  proyectosResolucion: [],
  formats: [],
  expedients: [],
});
