import isAuthenticatedGuard from "@/modules/auth/router/auth-guard";
import isRolDireccionGuard from "@/modules/direccion/router/direccion-rol-guard";

export default {

    beforeEnter: [isAuthenticatedGuard, isRolDireccionGuard],
    component: () =>
        import ("@/layouts/MainLayout.vue"),
    props: () => {
        return {
            menuItems: [
                {
                    label: "Seguimiento",
                    icon: "fa-solid fa-folder-open",
                    to: "/direcciones/administracion-proyectos",
                },
            ],
        };
    },
    children: [
        {
            path: "administracion-proyectos",
            beforeEnter: [isAuthenticatedGuard, isRolDireccionGuard],
            component: () =>
                import ("@/modules/direccion/views/SeguimientoProyectos"),
        }
    ],
};