import api from "@/api/laravelApi";


export const getProject = async(_, id) => {

    try {

        const { data: dataResponse } = await api.get(`/consejo/obtenerProyectoPorId/${id}`);
        const { status, message, data } = dataResponse;

        return { status, message, data };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }
};


export const getProyectosList = async({ commit }) => {
    try {
        const { data: info } = await api.get(`/consejo/obtenerProyectosConsejo`);
        const { status, data, message } = info;

        if (status != "success") return { ok: false, message };

        commit("setAllProyectosList", data);
        return { ok: true, message, data };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }
};

export const getProyectosAceptados = async({ commit }) => {
    try {
        const { data: info } = await api.post(`/consejo/getProjectsConsejo/11`);
        const { status, data, message } = info;

        if (status != "success") return { ok: false, message };

        commit("setProyectosAceptados", data);
        return { ok: true, message, data };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const getProyectosRechazados = async() => {
    try {
        const { data: info } = await api.post(`/consejo/getRejectedProjects`);
        const { status, data, message } = info;

        if (status != "success") return { ok: false, message };

        return { ok: true, message, data };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};


export const getProyectosRevision = async({ commit }) => {

    try {

        const { data } = await api.get('/consejo/obtenerProyectosRespuesta');

        const { status, data: proyectosRevision } = data;

        if (status == 'success') commit('setAllProyectosList', proyectosRevision);
        return status;

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const uploadRespuestaProject = async(_,  form ) => {
    try {
        const { data } = await api.post("/consejo/subirArchivosRespuesta", form);
        const { status, message } = data;

        if (status != "success") return { ok: false, message };

        return { ok: true, message };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};





export const getCatTecnicos = async() => {


    try {
        const { data } = await api.post("/consejo/getCatTecnicos");
        const { status, message, data: info } = data;

        if (status != "success") return { ok: false, message };

        return { ok: true, message, data: info };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const acceptProject = async(_, { form }) => {
    try {
        const { data } = await api.post("/consejo/assignedProject", {...form });
        const { status, message } = data;

        if (status != "success") return { ok: false, message };

        return { ok: true, message };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const getCatAsignaciones = async() => {
    try {
        const { data } = await api.post("/mesa/revision/getCatAsignaciones");
        const { status, message, data: info } = data;

        if (status != "success") return { ok: false, message };

        return { ok: true, message, data: info };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const reasignarProject = async(_, { form }) => {
    try {
        const { data } = await api.post("/mesa/revision/reasignarProject", {
            ...form,
        });
        const { status, message } = data;

        if (status != "success") return { ok: false, message };

        return { ok: true, message };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const downloadZip = async(_, id) => {
    try {
        const { data } = await api.post(`/consejo/downloadDirectoryZip/${id}`, _, {
            responseType: "blob",
        });
        const { status, message } = data;
        if (status == "error") return { ok: false, message };

        //await api.post(`/consejo/deleteZipPdf/${id}`);

        return { ok: true, message: "ok", data };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};



//----------------------------------------------Nuevo--------------------------------------------------//

/*export const getCatalogos = async({ commit }, dataInfo) => {

    try {
        console.log('entra catalogo independiente')
        const { idCatalogo, objectCatalogos } = dataInfo;
        const { data } = await api.get(`/consejo/obtenerCatalogo/${idCatalogo}`);
        await api.get(`/consejo/obtenerCatalogos`);
        const { status, data: info } = data;
        
        if (status == "success") commit(objectCatalogos[idCatalogo], info);

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

};*/


export const getCatalogos = async({ commit }) => {

    try {
       
        const objectCatalogos = {
            'CatalogoImpacto': 'setCatalogoImpactosProyecto',
            'CatalogoTipologia': 'setCatalogoTiposProyecto',
            'CatalogoPoblacionObjetivo': 'setCatalogoPoblacionObjetivo',
            'CatalogoEjecucionProyecto': 'setCatalogoEjecucionProyecto',
            'CatalogoBrindaraServicio': 'setCatalogoBrindaraServicio',
            'CatalogoAsignacionResolucion': 'setCatalogoAsignacionResolucion',
            'CatalogoDirecciones': 'setCatalogoDirecciones',
            'CatalogoPrioridad': 'setCatalogoPrioridad',
            'CatalogoTipoUsuario': 'setCatalogoTipoUsuario',
            'CatalogoAsignacion': 'setCatalogoAsignacion',
            'CatalogoDireccion': 'setCatalogoDireccion'
        };

        const { data } =  await api.get(`/consejo/obtenerCatalogos`);
        const { status, data: info } = data;
       
        if( status == 'success'){
             
            for ( const key in info ) {
                commit(`${objectCatalogos[key]}`, info[key] );
            }

        }

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

};

//----------------------------------------------Nuevo--------------------------------------------------//

export const getUsuariosDirección = async(_, idDireccion) => {
    try {

        const { data } = await api.get(`/consejo/obtenerUsuariosPorDireccion/${idDireccion}`);
        const { status, data: dataResponse } = data;

        return { status, dataResponse };


    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

};


export const sendValidationConsejo = async(_, dataSend) => {

    try {

        const { data } = await api.post(`/consejo/guardarResolucionConsejo`, dataSend);
        const { status, message } = data;

        return { status, message };


    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

};


export const getInputsForm = async({ commit }, idForm) => {

    try {

        const { data: info } = await api.get(`/consejo/obtenerFormularioDinamico/${idForm}`);

        const { status, data, message } = info;

        if (status == "success") {

            switch (idForm) {

                case 1:
                    commit("setInputsFormPE", data);
                    break;

                case 2:
                    commit("setInputsFormPA", data);
                    break;

                case 3:
                    commit("setInputsForm", data);
                    break;
            }

        }


        return { ok: true, message, data };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

};

//OBTENER PLANES ANUALES

export const getPlanesAnuales = async({ commit }) => {

    try {

        const { data } = await api.get('/consejo/obtenerAniosPlanAnual');

        const { status, data: planesAnuales } = data;

        if (status == 'success') commit('setPlanesAnuales', planesAnuales);

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const getOficinasDependencia = async({ commit }, upp) => {

    try {

        const { data } = await api.get(`/consejo/obtenerEntidadesUppUr/2/${upp}`);

        const { status, data: oficinas } = data;

        if (status == 'success') commit("setOficinas", oficinas);

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const obtenerArchivoProyecto = async(_, data) => {

    try {

        const { data: dataResponse } = await api.get(`/consejo/obtenerArchivoProyecto/${data.upp}/${data.idProyecto}/${data.seccion}/${data.clave}`);

        return dataResponse;

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}


export const descargarArchivoProyecto = async(_, dataSend) => {

    try {

        const { data: dataResponse } = await api.get(`/consejo/descargarArchivoProyecto/${dataSend.idProyecto}/${dataSend.seccion}/${dataSend.clave}/${dataSend.upp}`, {
            responseType: "blob",
        });

        return dataResponse;

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}


export const getInformacionPlanAnual = async(_, dataSend) => {

    try {

        const { data: dataResponse } = await api.get(`/consejo/obtenerInformacionPlanAnual/${dataSend.idDependencia}/${dataSend.planAnual}`);

        return dataResponse;

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const obtenerProyectosFechasSugeridas = async(_, idDependencia) => {

    try {

        const { data } = await api.get(`/consejo/obtenerProyectosFechasSugeridas/${idDependencia}`);
        const { status, data: dataResponse } = data;
        return { status, dataResponse };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const obtenerProyectosInventario = async(_, idDependencia) => {

    try {

        const { data } = await api.get(`/consejo/obtenerInventarioDependenciaSistemas/${idDependencia}`);
        const { status, data: dataResponse } = data;
        return { status, dataResponse };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const obtenerPlaneEstrategicoPorDependencia = async( _, idDependencia ) => {

    try {

        const { data } = await api.get(`/consejo/obtenerPlanEstrategicoPorDependencia/${idDependencia}`);
        const { status, data: dataResponse } = data;
        return { status, dataResponse };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }


}

export const obtenerArchivoPE = async(_, data) => {

    try {

        const { data: dataResponse } = await api.get(`/consejo/obtenerArchivoPlanEstrategico/${data.upp}/${data.seccion}`);

        return dataResponse;

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const descargarArchivoPE = async(_, data) => {

    try {

        const { data: dataResponse } = await api.get(`/consejo/descargarArchivoPlanEstrategico/${data.upp}/${data.seccion}`,{
            responseType: "blob",
        });

        return dataResponse;

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const getProyectosResolucionList = async({ commit }, opcionEstado) => {
    
    try {


        const { data: info } = await api.get(`/consejo/obtenerProyectosConResolucion/${opcionEstado}`);
        const { status, data, message } = info;

        if (status != "success") return { ok: false, message };

        commit("setProyectosResolucion", data);
        return { ok: true, message, data };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }
};

export const getProyectosSeguimiento = async({ commit }, opcionEstado) => {
    
    try {
        
        const { data: info } = await api.get(`/consejo/obtenerProyectosConResolucion/${opcionEstado}`);
        const { status, data, message } = info;

        if (status != "success") return { ok: false, message };

        commit("setProyectosAceptados", data);
        return { ok: true, message, data };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }
};

export const getInfoReports = async( _, idProyecto ) => {

    try {

        const { data: info } = await api.get(`/consejo/obtenerSeguimientoProyecto/${idProyecto}`);
        const { status, data } = info;
        return { status, data };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

};

export const obtenerReporte = async( _, data ) => {

    try {   
           
        if( data.action == 1 ){
           
            const { data: file } = await api.get(`/consejo/descargarReporteProyecto/${data.idProyecto}/${data.mes}`,{
                responseType: "blob",
            }); 
            
            return file;

        }else if( data.action == 2 ){
            
            const { data: dataResponse } = await api.get(`/consejo/obtenerReportePDF/${data.idProyecto}/${data.mes}`);
            const { status, file } = dataResponse;
            if( status == 'success') return { status, file };
            else return { status };

        }


    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

};









