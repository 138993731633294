import { computed, ref } from "vue";
import { useStore } from "vuex";

import { FilterMatchMode, FilterOperator } from "primevue/api";
//import useOpenDialogs from "./useOpenDialogs";
import Swal from "sweetalert2";
import moment from 'moment';
//import useOpenDialogsReportsType from "../composables/useOpenDialogsReportsType";

//Para reportes del tipo de proyecto 1
const dataFormReport1 = ref({
       'propuestaFile': null,
       'cotizacionFile': null,
       'cartaCompromisoFile': null,
       'sprintFile': null,
       'numeroServidores': '',
       'numeroCiudadanos': '',
       'procesoSinProyecto': '',
       'procesoConProyecto': '',
       'porcentajeArticulosOficina': '',
       'ahorro': '',
       'proyeccionTramites': '',
       'avancePresupuestal': 0.00,
       'avanceEjecucion': 0,
       'sendData': false ,
       'tableroControlFileFile': null, 
       'pruebasFuncionalesFile': null, 
       'manualUsuarioFile': null,
       'oficioEntregaFile': null, 
       'oficioLiberacionFile': null,
       'manualTecnicoFile': null,
       'diagramaDespliegueFile': null,
       'cargaInformacionFile': null, 
       'diagramaEntidadRelacionFile': null,
       'baseDatosFile': null,        
       'arquitecturaSistemaFile': null, 
       'imagenGraficaFile': null, 
       'dictamenCiberseguridadFile': null, 
       'licenciamientoCodigoFuenteFile': null, 
       'garantiaSistemaFile': null,
       'evaluacionImpactoFile': null, 
       'diccionarioDatosFile': null,
       'cartaPresupuestalFile': null,
       'formatoProyectoFile': null,
       'dictamenTecnicoFile': null,
       'dictamenDimensionamientoFile': null,
       'tramiteCadpeFile': null,
       'porcentajeAhorro': '',
       'evidenciaDocumentalFile': null,
       'evidenciaFotograficaFile': null,
       'planTrabajoCiberseguridadFile': null

    });
//

const clearDataReport = ()=>{

    dataFormReport1.value = {
        'propuestaFile': null,
        'cotizacionFile': null,
        'cartaCompromisoFile': null,
        'sprintFile': null,
        'numeroServidores': '',
        'numeroCiudadanos': '',
        'procesoSinProyecto': '',
        'procesoConProyecto': '',
        'porcentajeArticulosOficina': '',
        'ahorro': '',
        'proyeccionTramites': '',
        'avancePresupuestal': 0.00,
        'avanceEjecucion': 0,
        'sendData': false ,
        'tableroControlFileFile': null, 
        'pruebasFuncionalesFile': null, 
        'manualUsuarioFile': null,
        'oficioEntregaFile': null, 
        'oficioLiberacionFile': null,
        'manualTecnicoFile': null,
        'diagramaDespliegueFile': null,
        'cargaInformacionFile': null, 
        'diagramaEntidadRelacionFile': null,
        'baseDatosFile': null,        
        'arquitecturaSistemaFile': null, 
        'imagenGraficaFile': null, 
        'dictamenCiberseguridadFile': null, 
        'licenciamientoCodigoFuenteFile': null, 
        'garantiaSistemaFile': null,
        'evaluacionImpactoFile': null, 
        'diccionarioDatosFile': null,
        'cartaPresupuestalFile': null,
        'formatoProyectoFile': null,
        'dictamenTecnicoFile': null,
        'dictamenDimensionamientoFile': null,
        'tramiteCadpeFile': null,
        'porcentajeAhorro': '',
        'evidenciaDocumentalFile': null,
        'evidenciaFotograficaFile': null,
        'planTrabajoCiberseguridadFile': null
     }
     
}

const blockInputs = ref(false); 

const useSeguimiento = () => {

    const getProyectosConResolucion = async()=>{
        new Swal({
            title: "Cargando proyectos...",
            allowOutsideClick: false,
          });
        Swal.showLoading();
        const { status  } = await store.dispatch("mesa/getProyectosConResolucion");
        if( status == 'success' ) Swal.close();
    }

    /*const {
        showOpenDialogReport,
    } = useOpenDialogsReportsType();*/
    
    
    //const { toggleDialog } = useOpenDialogs();
    const store = useStore();
    const projectDialog = ref(false);
    const submitted = ref(false);
    const error = ref(false);
    const project = ref({});
    const user = ref({});
    const dep = ref({});
    const reportUpload = ref(null);
    let progressProject = ref(0);
    let observation = ref(null);
    let flagUploadSlider = ref(null);
    const fileReport = ref(null);
    const gastoPresupuesto = ref(null); 
    const montoGastadoProyecto = ref(null);
    const presupuestoProyecto = ref(null);
    const fechaAsignadaInicioProyecto = ref(null);
    const fechaAsignadaFinProyecto = ref(null);

    const columns = ref([

        { field: "folio", header: "Folio" },
        { field: "seccion.1.nombre", header: "Nombre" },
        { field: "seccion.3.plan_anual", header: "Plan Anual", class:"text-center"},
        /*{ field: "fecha_inicio_asignada", header: "Fecha inicio" },
        { field: "fecha_fin_asignada", header: "Fecha Fin" },*/
        { field: "estado", header: "Estado" },
        { field: "progreso_actual", header: "Avance", hidden: true },
        { field: "fecha_inicio_sugerida", header: "fecha_inicio_sugerida", hidden: true },
        { field: "fecha_fin_sugerida", header: "fecha_fin_sugerida", hidden: true },
        { field: "_id", header: "_id", hidden: true },

    ]);

    const colors = ref({
         11: 'rgb(34, 139, 34) !important',
         12: 'rgb(250, 130, 88) !important',
         13: '#dc3545 !important'
    });

    const filters1 = ref({

        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        project_folio: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        project_name: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        progress: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },

    });

    const editProject = (prod) => {

        project.value = {...prod };
        projectDialog.value = true;

    };

    const getAcceptedProjects = async() => {

        await store.dispatch("dependencias/getAcceptedProjects");

    };

    const getUserDep = async(user = 2) => {

        const resp = await store.dispatch("dependencias/getUserDep", user);
        return resp;

    };


    const getInputsForm = async() => {

        const resp = await store.dispatch("dependencias/getInputsForm");
        return resp;

    };

    const saveProject = async(state) => {

        submitted.value = true;
        error.value = false;
        let { form } = state;
        form.user = user.value.id;
        form.dependencia = dep.value.id_dependencia;

        const { ok, message } = await store.dispatch(
            "dependencias/createProject",
            form
        );

        if (!ok) {

            projectDialog.value = false;
            Swal.fire("Error", message, "error");

        } else {

            const { ok: okk, msg } = await getAcceptedProjects(dep.value.id_dependencia);

            if (!okk) {

                projectDialog.value = false;
                Swal.fire("Error", msg, "error");

            } else {

                projectDialog.value = false;
                Swal.fire("Creado", message, "success");

            }

        }

        submitted.value = false;
    };

    const setValueTextArea = (value) => {

        observation.value = value;

    }

    const uploadAvanceEjecucion = ( idProject ) => {

        const targetDialog = document.getElementById('dialogUploadReports');

        Swal.fire({

            target: targetDialog,
            text: "¿Estás seguro que deseas enviar tu avance del proyecto?",
            icon: "question",
            showCancelButton: true,
            cancelButtonColor: "#f38ec0",
            confirmButtonColor: "#6a0f49",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",

        }).then(async(result) => {

            if (result.isConfirmed) {

                let form = new FormData();

                if( gastoPresupuesto.value != null ){

                    form.append('idProyecto', idProject);
                    form.append('progreso', progressProject.value);
                    if(fileReport.value != null )  form.append('reporte', fileReport.value);
                    form.append('observacion', observation.value);
                    form.append('gasto_presupuesto', gastoPresupuesto.value);
                    let mensaje = '';
                    const { status: estado, message } = await store.dispatch('dependencias/uploadReport', form);
                    mensaje = estado == 'error' ? 'Ha ocurrido un error' : message;
                    
                    Swal.fire({
    
                        target: targetDialog,
                        showConfirmButton: false,
                        timer: 2400,
                        text: mensaje,
                        icon: estado
    
                    });
    
                    if( estado === 'success' ) /*showReports(idProject) && */getAcceptedProjects(); //input.$refs.reportUpload[0].value = null;

                }else{

                    Swal.fire({
    
                        target: targetDialog,
                        showConfirmButton: false,
                        timer: 2400,
                        text: 'Campo monto gastado es obligatorio',
                        icon: 'info'
    
                    });

                }
                

            } else {

                self.$refs.reportUpload[0].value = null;

            }

        });

    }

    const downloadReport = async(arrayData) => {

        new Swal({
            target: document.getElementById('dialogUploadReports'),
            title: "Obteniendo reporte...",
            allowOutsideClick: false,
        });
      
        Swal.showLoading();
        let extension;
        const response = await store.dispatch("dependencias/downloadReport", arrayData);

        const { data:file, ok } = response;

        if( ok ){  
            
            const url = window.URL.createObjectURL(new Blob([file]));
            const link = document.createElement("a");
            link.href = url;
            if( file.type.includes('officedocument') ) extension = 'docx';
            else if( file.type.includes('x-rar') ) extension = 'rar';
            else extension = file.type.split('/')[1];
            link.setAttribute("download", "reporte." + extension);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            Swal.close();

        }else{

            Swal.fire('info', 'Ha ocurrido un error', 'info');
            
        }

    }

    const downloadFiles = async(idProject, folioProject) => {

        new Swal({
            title: "Obteniendo expediente...",
            allowOutsideClick: false,
        });

        Swal.showLoading();

        const { ok, message, data } = await store.dispatch("dependencias/downloadDirectoryZip", idProject);

        if (!ok) {

            Swal.fire("Error", message, "error");

        } else {

            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute('download', 'expediente_' + folioProject + '_.zip');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            Swal.close();

        }

    }

    const sendAssingDates = async(idProject, assingStartDate, assingEndDate) => {

        let startDate = moment(assingStartDate).format('YYYY-MM-DD');
        let endDate = moment(assingEndDate).format('YYYY-MM-DD');
        const targetDialog = document.getElementById('dialogDates');

        const form = new FormData;
        form.append('idProject', idProject);
        form.append('startDate', startDate);
        form.append('endDate', endDate);

        const { status, message } = await store.dispatch("dependencias/sendAssingDates", form);

        Swal.fire({
            target: targetDialog,
            icon: status,
            text: message,
            confirmButtonColor: "#6a0f49",
            confirmButtonText: "Aceptar"
        });

        if (status == 'success') getAcceptedProjects();

        return message;
    }

    const getPDFReport = async( data )=>{
       
        const { status, file } = await store.dispatch("dependencias/obtenerReportePDF", data);
        if( status == 'success' ) return file;
    }

    const sendReport = async( projectId, dependenciaId, tipoFormato, fechaReporte, tipoProyecto, progreso )=>{

        const objectData = {};
        objectData['avanceEjecucion'] = progreso;
        objectData['avancePresupuestal'] = dataFormReport1.value.avancePresupuestal;
        objectData['fechaReporte'] = fechaReporte;
        objectData['tipoFormato'] = tipoFormato;
        objectData['tipoProyecto'] = tipoProyecto;
        
       if( tipoProyecto == 1 && tipoFormato  == 1 ){

            objectData['ahorro'] = dataFormReport1.value.ahorro;
            objectData['cartaCompromisoFile'] = dataFormReport1.value.cartaCompromisoFile;
            objectData['cotizacionFile'] = dataFormReport1.value.cotizacionFile;
            objectData['numeroCiudadanos'] = dataFormReport1.value.numeroCiudadanos;
            objectData['numeroServidores'] = dataFormReport1.value.numeroServidores;
            objectData['porcentajeArticulosOficina'] = dataFormReport1.value.porcentajeArticulosOficina;
            objectData['procesoConProyecto'] = dataFormReport1.value.procesoConProyecto;
            objectData['procesoSinProyecto'] = dataFormReport1.value.procesoConProyecto;
            objectData['propuestaFile'] = dataFormReport1.value.propuestaFile;
            objectData['proyeccionTramites'] = dataFormReport1.value.proyeccionTramites;
            objectData['sprintFile'] = dataFormReport1.value.sprintFile;
            objectData['cartaPresupuestalFile'] = dataFormReport1.value.cartaPresupuestalFile;
            objectData['dictamenTecnicoFile'] = dataFormReport1.value.dictamenTecnicoFile;
            objectData['formatoProyectoFile'] = dataFormReport1.value.formatoProyectoFile;

       }
       
       if( tipoProyecto == 1 && tipoFormato == 2){

            objectData['sprintFile'] = dataFormReport1.value.sprintFile;

       }else if( tipoProyecto == 1 && tipoFormato == 3){
           
            objectData['tableroControlFile'] = dataFormReport1.value.tableroControlFile;
            objectData['pruebasFuncionalesFile'] = dataFormReport1.value.pruebasFuncionalesFile;
            objectData['manualUsuarioFile'] = dataFormReport1.value.manualUsuarioFile;
            objectData['oficioEntregaFile'] = dataFormReport1.value.oficioEntregaFile;
            objectData['oficioLiberacionFile'] = dataFormReport1.value.oficioLiberacionFile;
            objectData['manualTecnicoFile'] = dataFormReport1.value.manualTecnicoFile;
            objectData['diagramaDespliegueFile'] = dataFormReport1.value.diagramaDespliegueFile;
            objectData['cargaInformacionFile'] = dataFormReport1.value.cargaInformacionFile;
            objectData['diagramaEntidadRelacionFile'] = dataFormReport1.value.diagramaEntidadRelacionFile;
            objectData['baseDatosFile'] = dataFormReport1.value.baseDatosFile;
            objectData['arquitecturaSistemaFile'] = dataFormReport1.value.arquitecturaSistemaFile;
            objectData['imagenGraficaFile'] = dataFormReport1.value.imagenGraficaFile;
            objectData['dictamenCiberseguridadFile'] = dataFormReport1.value.dictamenCiberseguridadFile;
            objectData['licenciamientoCodigoFuenteFile'] = dataFormReport1.value.licenciamientoCodigoFuenteFile;
            objectData['garantiaSistemaFile'] = dataFormReport1.value.garantiaSistemaFile;
            objectData['evaluacionImpactoFile'] = dataFormReport1.value.evaluacionImpactoFile;
            objectData['diccionarioDatosFile'] = dataFormReport1.value.diccionarioDatosFile;
            objectData['sprintFile'] = dataFormReport1.value.sprintFile;

        }else if( ( tipoProyecto == 2 || tipoProyecto == 5 ) &&  tipoFormato == 1 ){

            objectData['cartaPresupuestalFile'] = dataFormReport1.value.cartaPresupuestalFile;
            objectData['cotizacionFile'] = dataFormReport1.value.cotizacionFile;
            objectData['formatoProyectoFile'] = dataFormReport1.value.formatoProyectoFile;
            objectData['dictamenTecnicoFile'] = dataFormReport1.value.dictamenTecnicoFile;
            objectData['dictamenDimensionamientoFile'] = dataFormReport1.value.dictamenDimensionamientoFile;
            objectData['tramiteCadpeFile'] = dataFormReport1.value.tramiteCadpeFile;
            objectData['numeroServidores'] = dataFormReport1.value.numeroServidores;
            objectData['numeroCiudadanos'] = dataFormReport1.value.numeroCiudadanos;
            objectData['procesoSinProyecto'] = dataFormReport1.value.procesoConProyecto;
            objectData['procesoConProyecto'] = dataFormReport1.value.procesoConProyecto;
            objectData['porcentajeArticulosOficina'] = dataFormReport1.value.porcentajeArticulosOficina;
            objectData['porcentajeAhorro'] = dataFormReport1.value.porcentajeArticulosOficina;
            objectData['proyeccionTramites'] = dataFormReport1.value.proyeccionTramites;
        
        }

        dataFormReport1.value.sendData = true;  
        dataFormReport1.value.avanceEjecucion = progressProject.value;  
        const formData = new FormData();
        formData.set('idProyecto', projectId);
        formData.set('idDependencia', dependenciaId);
        let execute = true;

        for (const [key, value] of Object.entries(objectData)) {

            if( value === '' || value === null ){
                execute = false;
                break;
            }else{
                formData.set(key, value)
            }

        }

        if( execute ){

            Swal.fire({
                text: "¿Estás seguro que deseas enviar el primer reporte del  proyecto?",
                icon: "question",
                showCancelButton: true,
                cancelButtonColor: "#f38ec0",
                confirmButtonColor: "#6a0f49",
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cancelar",

            }).then(async(result) => {
    
                if (result.isConfirmed) {
                    new Swal({
                        title: "Subiendo reporte...",
                        allowOutsideClick: false,
                    });
                    Swal.showLoading();
                    const { status, message } = await store.dispatch("dependencias/enviarReporteT1", formData);
                    //await getAceptedProjects();
                    await getAcceptedProjects()
                    Swal.close();
                    if( status == 'success'){
                        //toggleDialog();
                        clearDataReport();
                        //showOpenDialogReport( null, null, null, false );
                        
                    }
                    const mensaje = message == 'error' ? 'Ha ocurrido un error' : message;
                     
                    Swal.fire({
                        'icon': status,
                        'text': mensaje,
                        'confirmButtonText': "Aceptar",
                        'confirmButtonColor': "#6a0f49",
                    })
                }
            })
            
        }else{

            Swal.fire({
                'icon': 'info',
                'title': 'Información',
                'text': 'Reporte NO enviado. Es necesario ingresar todos los campos',
                'confirmButtonText': "Aceptar",
                'confirmButtonColor': "#6a0f49",
            })
        }
    }

    const sendReportT2 = async( projectId, dependenciaId, tipoFormato, fechaReporte, tipoProyecto, progreso )=>{

        const objectData = {};
        objectData['avanceEjecucion'] = progreso;
        objectData['avancePresupuestal'] = dataFormReport1.value.avancePresupuestal;
        objectData['fechaReporte'] = fechaReporte;
        objectData['tipoFormato'] = tipoFormato;
        objectData['tipoProyecto'] = tipoProyecto;
        if( tipoProyecto == 2 && tipoFormato == 1 ) objectData['dictamenDimensionamientoFile'] = dataFormReport1.value.dictamenDimensionamientoFile;

        if( tipoFormato  == 1 ){

            objectData['cartaPresupuestalFile'] = dataFormReport1.value.cartaPresupuestalFile;
            objectData['cotizacionFile'] = dataFormReport1.value.cotizacionFile;
            objectData['formatoProyectoFile'] = dataFormReport1.value.formatoProyectoFile;
            objectData['dictamenTecnicoFile'] = dataFormReport1.value.dictamenTecnicoFile;
            objectData['tramiteCadpeFile'] = dataFormReport1.value.tramiteCadpeFile;
            objectData['numeroServidores'] = dataFormReport1.value.numeroServidores;
            objectData['numeroCiudadanos'] = dataFormReport1.value.numeroCiudadanos;
            objectData['procesoSinProyecto'] = dataFormReport1.value.procesoConProyecto;
            objectData['procesoConProyecto'] = dataFormReport1.value.procesoConProyecto;
            objectData['porcentajeArticulosOficina'] = dataFormReport1.value.porcentajeArticulosOficina;
            objectData['porcentajeAhorro'] = dataFormReport1.value.porcentajeAhorro;
            objectData['proyeccionTramites'] = dataFormReport1.value.proyeccionTramites;

        }else{

            objectData['evidenciaDocumentalFile'] = dataFormReport1.value.evidenciaDocumentalFile;
            objectData['evidenciaFotograficaFile'] = dataFormReport1.value.evidenciaFotograficaFile;

        }


        dataFormReport1.value.sendData = true;  
        dataFormReport1.value.avanceEjecucion = progressProject.value;  
        const formData = new FormData();
        formData.set('idProyecto', projectId);
        formData.set('idDependencia', dependenciaId);
        let execute = true;

        for (const [key, value] of Object.entries(objectData)) {

            if( value === '' || value === null ){
                execute = false;
                break;
            }else{
                formData.set(key, value)
            }

        }

        if( execute ){

            Swal.fire({
                text: "¿Estás seguro que deseas enviar el primer reporte del  proyecto?",
                icon: "question",
                showCancelButton: true,
                cancelButtonColor: "#f38ec0",
                confirmButtonColor: "#6a0f49",
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cancelar",

            }).then(async(result) => {
    
                if (result.isConfirmed) {
                    new Swal({
                        title: "Subiendo reporte...",
                        allowOutsideClick: false,
                    });
                    Swal.showLoading();
                    const { status, message } = await store.dispatch("dependencias/enviarReporteT2", formData);
                    //await getAceptedProjects();
                    await getAcceptedProjects()
                    Swal.close();
                    if( status == 'success'){
                        //toggleDialog();
                        clearDataReport();
                        //showOpenDialogReport( null, null, null, false );
                        
                    }
                    const mensaje = message == 'error' ? 'Ha ocurrido un error' : message;
                     
                    Swal.fire({
                        'icon': status,
                        'text': mensaje,
                        'confirmButtonText': "Aceptar",
                        'confirmButtonColor': "#6a0f49",
                    })
                }
            })
            
        }else{

            Swal.fire({
                'icon': 'info',
                'title': 'Información',
                'text': 'Reporte NO enviado. Es necesario ingresar todos los campos',
                'confirmButtonText': "Aceptar",
                'confirmButtonColor': "#6a0f49",
            })
        }
    }

    const sendReportT3 = async( projectId, dependenciaId, tipoFormato, fechaReporte, tipoProyecto )=>{

        const objectData = {};
        objectData['avanceEjecucion'] = 100;
        objectData['avancePresupuestal'] = dataFormReport1.value.avancePresupuestal;
        objectData['planTrabajoCiberseguridadFile'] = dataFormReport1.value.planTrabajoCiberseguridadFile;
        objectData['fechaReporte'] = fechaReporte;
        objectData['tipoFormato'] = tipoFormato;
        objectData['tipoProyecto'] = tipoProyecto;
        dataFormReport1.value.sendData = true;  

        const formData = new FormData();
        formData.set('idProyecto', projectId);
        formData.set('idDependencia', dependenciaId);
        let execute = true;

        for (const [key, value] of Object.entries(objectData)) {

            if( value === '' || value === null ){
                console.log(key)
                execute = false;
                break;
            }else{
                formData.set(key, value)
            }

        }

        if( execute ){

            Swal.fire({
                text: "¿Estás seguro que deseas enviar el primer reporte del  proyecto?",
                icon: "question",
                showCancelButton: true,
                cancelButtonColor: "#f38ec0",
                confirmButtonColor: "#6a0f49",
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cancelar",

            }).then(async(result) => {
    
                if (result.isConfirmed) {
                    new Swal({
                        title: "Subiendo reporte...",
                        allowOutsideClick: false,
                    });
                    Swal.showLoading();
                    const { status, message } = await store.dispatch("dependencias/enviarReporteT3", formData);
                    //await getAceptedProjects();
                    await getAcceptedProjects()
                    Swal.close();
                    if( status == 'success'){
                        //toggleDialog();
                        clearDataReport();
                        //showOpenDialogReport( null, null, null, false );
                        
                    }
                    const mensaje = message == 'error' ? 'Ha ocurrido un error' : message;
                     
                    Swal.fire({
                        'icon': status,
                        'text': mensaje,
                        'confirmButtonText': "Aceptar",
                        'confirmButtonColor': "#6a0f49",
                    })
                }
            })
            
        }else{

            Swal.fire({
                'icon': 'info',
                'title': 'Información',
                'text': 'Reporte NO enviado. Es necesario ingresar todos los campos',
                'confirmButtonText': "Aceptar",
                'confirmButtonColor': "#6a0f49",
            })
        }
    }

    const setFileToState =( refFile, file )=> dataFormReport1.value[refFile] = file;
    

    const obtenerDocumentosReporte  = async( idProyecto, clave, date, action ) =>{
        
        new Swal({
            target: document.getElementById('dialogUploadReports'),
            title: "Obteniendo documento...",
            allowOutsideClick: false,
        });
        Swal.showLoading();
        const { status, message, fileBase64 } = await store.dispatch("mesa/obtenerDocumentosReporte", { idProyecto, clave, date });
        Swal.close();
        if( status == 'success' ){

            if( action == 1 ){ //Descargar file
                let extension = 'pdf';
                if( clave == 'evidenciaDocumentalFile' || clave == 'evidenciaFotograficaFile' ) extension = 'zip';
                const byteCharacters = atob(fileBase64);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: `application/${extension}` });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${clave}.${extension}`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            }else if( action == 2 ){ //Previsualizar PDF

                return fileBase64;
            }
    
        }else{

            Swal.fire({
                title: 'Información',
                text: status == 'error' ? 'Ha ocurrido un error' : message,
                icon: status,
                showConfirmButton: false,
                timer: 3000
            });


        }
    }
    /*End primer reporte*/

    const getFormatsReport = async( formato ) =>{
        alert('entra');
        const archivo = formato.split('.');
        const nombreArchivo = archivo[0];
        const extension = archivo[1];
        
        new Swal({
            target: document.getElementById('dialogUploadReports'),
            title: "Obteniendo formato...",
            allowOutsideClick: false,
        });
        Swal.showLoading();
        const { status, message, fileBase64 } = await store.dispatch("mesa/obtenerFormatosReporte", formato);
        Swal.close();


        if( status == 'success'){
        
            const byteCharacters = atob(fileBase64);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: `application/${extension}` });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${nombreArchivo}.${extension}`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        }else{

            console.log(message)

        }

    }

    //Precargar datos del reporte a partir de la data que obtenemos en la API de obtener reportes
    const previewReporte = ( projects, projectId, date ) => {
        blockInputs.value = true;
        const currentProject = projects.value.filter(proyecto => proyecto._id === projectId.value);
        const currentReport = currentProject[0].reportes[date.value];
        Object.entries(currentReport).forEach(([clave, valor]) => {
            dataFormReport1.value[clave] =  valor;
        });
    }


    return {

        getAcceptedProjects,
        getUserDep,
        getInputsForm,
        projects: computed(() => store.getters["dependencias/getAceptedProjects"]),
        columns,
        filters1,
        projectDialog,
        editProject,
        submitted,
        project,
        saveProject,
        user,
        dep,
        //showReports,
        reportsIndex: computed(() => store.getters["dependencias/getReportsIndex"]),
        downloadReport,
        uploadAvanceEjecucion,
        setValueTextArea,
        reportUpload,
        progressProject,
        flagUploadSlider,
        downloadFiles,
        sendAssingDates,
        fileReport,
        gastoPresupuesto,
        presupuestoProyecto,
        montoGastadoProyecto,
        fechaAsignadaInicioProyecto,
        fechaAsignadaFinProyecto,
        getPDFReport,
        colors,
        dataFormReport1,
        sendReport,
        setFileToState,
        obtenerDocumentosReporte,
        getFormatsReport,
        clearDataReport,
        sendReportT2,
        previewReporte,
        blockInputs,
        sendReportT3,
        getProyectosConResolucion,
        projectsResolution: computed(() => store.getters["mesa/getProyectosResolucion"]),
    };
};

export default useSeguimiento;