<template>
  <HeaderComponent />
  <div class="card flex align-items-center">
    <TableComponent />
  </div>
</template>

<script>
import HeaderComponent from "../components/ListadoProyectos/HeaderComponent.vue";
import TableComponent from "../components/ListadoProyectos/TableComponent.vue";
export default {
  components: {
    HeaderComponent,
    TableComponent,
  },
  setup() {},
};
</script>