import api from "@/api/laravelApi";

export const signInUser = async({ commit }, usuario) => {

    try {

        const { username: email, password } = usuario;

        const { data } = await api.post("/autenticacion/autenticacion", {

            usuario: email,
            contraseña: password,

        });

        const { status, message } = data;

        if (status == "error" || status == "info") return { status, message };

        const { data: user, token } = data;

        commit("loginUser", { user, token });

        return { ok: true, message: "ok", tipo_user: user.tipo_usuario.id };

    } catch (err) {
        // console.log("error auth store actions: ", err);
        let msg;
        if (err.response) {
            msg = err.response?.data?.message;
        } else if (err.request) {
            msg = err.request;
        }

        if (!msg) {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const verifyAuth = async({ commit }) => {

    const token = localStorage.getItem("token");

    if (!token) {
        commit("logout");
        return { ok: false, message: "No hay token" };
    }

    try {
        const { data } = await api.get("/autenticacion/obtenerInformacionUsuario");
        const { status, dataUsuario, message } = data;

        if (status != "success") {
            commit("logout");
            return { ok: false, message };
        }

        //si no hacemos el commit de abajo, se pierde el estado
        commit("loginUser", { dataUsuario, token });
        return { ok: true, message };

    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        }
        if (!msg) {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const checkStatusInfoCompleted = async(_, userId) => {
    try {
        const { data } = await api.post("autenticacion/validarInformacionUsuario", {
            userId,
        });
        const { status, message } = data;
        if (status == "info") return { ok: false, message };

        return { ok: true, message: "ok" };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        }
        if (!msg) {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const checkPasswdWasUpdated = async(_, userId) => {
    try {
        const { data } = await api.post("/autenticacion/validarContrasenaActualizada", {
            userId,
        });
        const { status, message } = data;
        if (status == "info") return { ok: false, message };

        return { ok: true, message: "ok" };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        }
        if (!msg) {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const changePass = async(_, { contraseña }) => {
    try {
        const { data } = await api.post("/autenticacion/modificarContraseña", {
            contraseña: contraseña
        });

        return data;

    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        }
        if (!msg) {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const updateUserByYourself = async(_, user) => {
    try {
        const { data: info } = await api.post(`autenticacion/editarInformacionInicioUsuario`, {
            ...user,
        });
        const { status, message } = info;

        return { status, message };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
    
};

export const userDesautenticacion = async() => {
    try {

        const { message } = await api.post('autenticacion/desautenticacion');
        console.log(message);

    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};