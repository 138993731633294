<template>
<br>
 <div class="grid bg-pink-50 w-full">
    <div class="col text-center">
      <h6><b>{{dataProject.dependencia}}</b></h6>
    </div>
 </div>
 <div class="grid bg-pink-50 w-full">
    <div class="col text-right">
      <ButtonVue 
          type="button" 
          class="bg-pink-900"
          label="Plan Estratégico" 
          icon="fa fa-cubes" 
          v-tooltip.top="'Da clic para ver el Plan Estratégico'"
          @click="showDialog(
                    'dialogVerPlanEstrategico',
                    null,
                    null,
                    null,
                    null,
                    dataProject.dependencia_id,
                    dataProject.dependencia,
                    dataProject
                 )"
          outlined 
      />
    </div>
    <div class="col text-left">
      <ButtonVue 
            class="bg-pink-900"
            type="button" 
            label="Planes Anuales" 
            icon="fa fa-list" 
            v-tooltip.top="'Da clic para ver el Plan Anual'"
            @click="showDialog(
                    'dialogVerPlanAnual',
                    null,
                    null,
                    null,
                    null,
                    dataProject.dependencia_id,
                    dataProject.dependencia,
                    dataProject
                 )"
            outlined 
      />
    </div>
  </div>
  <DataTableVue
    :value="allProyectsState"
    responsiveLayout="scroll"
    v-model:filters="filters1"
    stripedRows
    :paginator="true"
    :rows="10"
    paginatorTemplate="CurrentPageReport  PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown"
    :rowsPerPageOptions="[10, 20, 30]"
    removableSort
    dataKey="id"
    class="p-datatable-sm"
    style="width: 100%"
    v-model:selection="dataProject"
    selectionMode="single"
  >
    <template #header>
      <div class="grid">
        <div class="col-12">
          <div class="flex justify-content-end">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputTextV
                v-model="filters1['global'].value"
                placeholder="Buscar"
              />
            </span>
          </div>
        </div>
      </div>
    </template>
    <template #empty> No se encontraron resultados. </template>
        <ColumnVue
          v-for="col of columns"
          :field="col.field"
          :header="col.header"
          :hidden="col.hidden"
          :key="col.field"
          :sortable="true"
          class="border-dashed border-300"
        >
        </ColumnVue>
        <ColumnVue header="Opciones">
          <template #body="slotProps">
            <div v-if="slotProps.data.estado_id == 10">
              <ButtonVue 
                class="p-button-rounded bg-green-500 m-1" 
                size="small"
                icon="pi pi-user-edit" 
                aria-label="Search"
                @click=" openDialog(
                  'dialogResolucionProyecto',
                  slotProps.data.folio,
                  null,
                  null,
                  slotProps.data._id,
                  slotProps.data.dependencia_id,
                  null,
                  slotProps.data.seccion[3]
                )"
                v-tooltip.top="'Emitir una resolución del proyecto'"
              />
               <ButtonVue 
                class="p-button-rounded p-button-info m-1" 
                size="small"
                icon="pi pi-eye" 
                aria-label="Search"
                @click=" openDialog(
                  'dialogVerFichaProyecto',
                  slotProps.data.folio,
                  null,
                  null,
                  slotProps.data._id,
                   slotProps.data.dependencia_id
                )"
                v-tooltip.top="'Ver ficha técnica de este proyecto'"
              />     
            </div>
      </template>
    </ColumnVue>
  </DataTableVue>
  <DialogVerFichaProyectoComponent v-if="hasRole('dialogVerFichaProyecto')" />
  <DialogResolucionProyecto v-if="hasRole('dialogResolucionProyecto')" />
  <DialogVerPlanAnualComponent v-if="hasRole('dialogVerPlanAnual')" />
  <DialogPlanEstrategicoComponent v-if="hasRole('dialogVerPlanEstrategico')"/>
  <!--<DialogRechazarProyectoComponent v-if="hasRole('dialogRechazarProyecto')" />-->
</template>

<script>
import { onMounted, defineAsyncComponent, ref } from "vue";
import useProyectosList from "../../composables/useProyectosConsejo";
import useOpenDialogs from "../../composables/useOpenDialogs";

export default {
  components: {
    DialogVerFichaProyectoComponent: defineAsyncComponent(() =>
      import("./DialogVerFichaProyecto.vue")
    ),
    DialogResolucionProyecto: defineAsyncComponent(() =>
      import("./DialogResolucionProyecto.vue")
    ),
    DialogVerPlanAnualComponent: defineAsyncComponent(() =>
      import("./DialogVerPlanAnual.vue")
    ),
    DialogPlanEstrategicoComponent: defineAsyncComponent(() =>
      import("./DialogVerPlanEstrategico.vue")
    ),
  },

  setup() {

    const { openDialog, hasRole } = useOpenDialogs();

    const dataProject = ref({});

    const {

      getProyectosList,
      getAllInputsForm,
      columns,
      filters1,
      allProyectsState,
      Swal,
      downloadZip,
      getCatalogos,
      getInputsForm,
      getPlanesAnuales
      
    } = useProyectosList();

    onMounted(async () => {
      new Swal({
        title: "Cargando información...",
        allowOutsideClick: false,
      });
      Swal.showLoading();
      await getPlanesAnuales();
      await getInputsForm(3);
      await getInputsForm(2);
      await getInputsForm(1);
      await getCatalogos(0);
      await getProyectosList();
      Swal.close();
     
    });

    const showDialog =( role = "", project, id, name, _id, uppDependencia, nombreDependenciaSelected = null, dataProject )=>{
     
      if( uppDependencia ){ 
           let dataProyecto =  dataProject != null ? dataProject.seccion[3].plan_anual : null;
           openDialog( role , project, id, name, _id, uppDependencia, nombreDependenciaSelected, null, dataProyecto )
      }else{
        Swal.fire({
                title: 'Información',
                text: 'Primero seleccione un proyecto',
                icon: 'info',
                showConfirmButton: false,
                timer: 2000,
             });
      }
  
    }

    return {
      columns,
      getProyectosList,
      allProyectsState,
      filters1,
      hasRole,
      openDialog,
      campos: getAllInputsForm,
      downloadZip,
      dataProject,
      showDialog
    };
  },
};
</script>
<style scoped>
.p-splitbutton {
  width: 80px;
  height: 35px;
}
</style>    