// export const myGetter = (state) => {
// return state
// }

export const getAllProjectsReception = (state) => {
  return state.projects_reception;
};

export const getAllProjectsRevision = (state) => {
  return state.projects_revision;
};

export const getAllProjectsDictamen = (state) => {
  return state.projects_request;
};

export const getProject = (state) => {
  return state.project;
};

export const getAllInputsForm = (state) => {
  return state.inputs;
};

export const getConvenios = (state) => {      
  return state.convenios;
};

export const getDependencias = (state) => {      
  return state.dependencias;
};

export const getPlanesDependencia = (state) => {      
  return state.planesDependencia;
};

export const getCatalogoImpactosProyecto = (state) => {
  return state.catalogoImpactosProyecto;
}

export const getCatalogoTiposProyecto = (state) => {
  return state.catalogoTiposProyecto;
}

export const getCatalogoPoblacionObjetivo = (state) => {
  return state.catalogoPoblacionObjetivo;
}

export const getCatalogoEjecucionProyecto = (state) => {
  return state.catalogoEjecucionProyecto;
}

export const getCatalogoBrindaraServicio = (state) => {
  return state.catalogoBrindaraServicio;
}

export const getCatalogoPrioridad = (state) => {
  return state.catalogoPrioridad;
}

export const getOficinas = (state) => {
  return state.oficinas;
}

export const getInputsPA = (state) => {
  return state.inputsPA;
}

export const getInputsPE = (state) => {
  return state.inputsPE;
}

export const getProyectos = (state) => {
  return state.proyectos;
}

export const getInventarioProyectos = (state) => {
  return state.inventarioProyectos;
}

export const getPlanesAnuales = (state) => {
  return state.planesAnuales;
}

export const getRevisores = (state) => {
  return state.revisores;
}

export const getProyectosRevisores = (state) => {
  return state.proyectosRevisores;
}

export const getCatalogoDependencias = (state) => {
  return state.catalogoDependencias;
}

 //Administrador
export const getAllUsers = (state) => {
return state.users;
};

export const getRoles = (state) => {
  return state.roles;
};

export const getVentanas = (state) => {
  return state.ventanas;
 };

export const getTecnicos = (state) => {
  return state.tecnicos;
};

export const getProyectosRevision = (state) => {
  return state.proyectosRevision;
};


export const getProyectosResolucion = (state) => {
  return state.proyectosResolucion;
};

export const getFormats = (state) => {
  return state.formats;
};

export const getExpedients = (state) => {
  return state.expedients;
};





