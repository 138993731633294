// export const myAction = async ({commit}) => {

// }

import api from "@/api/laravelApi";

export const getVentana = async( /*{ commit }*/ ) => {

    try {

        const { data: info } = await api.get("/dependencia/obtenerVentanaActiva");
        const { status, data } = info;
        return { status, data } //if( status == 'success' )commit("setVentana", data);

    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const getProject = async(_, id) => {
    try {
        const { data: dataResponse } = await api.get(`/dependencia/obtenerProyectoPorId/${id}`);
        const { status, message, data } = dataResponse;

        return { status, message, data };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }
};
export const getProjects = async({ commit }) => {

    try {
        const { data: info } = await api.get(`/dependencia/obtenerProyectosPorDependencia`);
        const { status, message, data } = info;

        if (status != "success") return { ok: false, message };

        commit("setProyectos", data);
        return { ok: true, message, data };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

};

export const getUserDep = async({ commit }, user) => {
    try {
        const { data: info } = await api.post(
            `/dependencias/getUserDependencia/${user}`
        );
        const { status, data, message } = info;

        if (status != "success") return { ok: false, message };

        commit("setUserDep", data);
        return { ok: true, message, data };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const getInputsForm = async({ commit }, idForm) => {

    try {

        const { data: info } = await api.get(`/dependencia/obtenerFormularioDinamico/${idForm}`);

        const { status, data, message } = info;

        if (status != "success") return { ok: false, message };

        commit("setInputsForm", data);

        return { ok: true, message, data };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

};

export const obtenerCatalogos = async({ commit }, arrayData) => {

    try {
        const setReferencia = arrayData[1][arrayData[0]];
        const { data: dataResponse } = await api.get(`/dependencia/obtenerCatalogo/${arrayData[0]}`);
        const { data, status } = dataResponse;
        if (status == 'success') commit(setReferencia, data);


    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}


export const getAnnualPlans = async() => {

    try {

        const { data: dataResponse } = await api.get("/dependencia/obtenerAniosPlanAnual");
        const { data, status, message } = dataResponse;

        return status != "success" ? { ok: false, message } : { ok: true, data, message };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

}

export const createProject = async(_, form) => {
    try {
        const { data: info } = await api.post(`/dependencias/createProject`, {
            ...form,
        });
        const { status, message } = info;
        if (status != "success") return { ok: false, message };

        return { ok: true, message };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

//---------------------------------- INICIO MÉTODOS PARA DialogAddFilesComponent -------------------------------------------------------
export const saveFiles = async(_, { files, id, requiredOrNotFiles }) => {
    try {
        const formData = new FormData();
        Object.entries(requiredOrNotFiles).forEach(([key]) => {
            // console.log(key);
            if (files[key]) {
                formData.append(key, files[key]);
            }
        });
        formData.append("project_id", id);
        formData.append("flag", 1);

        const { data: info } = await api.post(
            `/dependencias/uploadFilesDependencia`,
            formData
        );
        const { status, message } = info;
        if (status != "success") return { ok: false, message };

        return { ok: true, message: "ok" };
    } catch (err) {
        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };
    }
};

export const getFiles = async({ commit }) => {
    try {
        const { data: info } = await api.post("/dependencias/getFiles");
        const { status, data, message } = info;

        if (status != "success") return { ok: false, message };

        commit("setFiles", data);
        return { ok: true, message, data };
    } catch (err) {
        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };
    }
};

export const editFichaProject = async({ commit }, form) => {
    try {
        const { data: info } = await api.post(`/dependencias/editFichaProject`, {
            ...form,
        });
        const { status, data, message } = info;

        if (status != "success") return { ok: false, message };
        commit("setProject", data);

        return { ok: true, message, data };
    } catch (err) {
        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };
    }
};

//---------------------------------- INICIO MÉTODOS PARA DialogEditFilesComponent -------------------------------------------------------
export const getUploadedFiles = async(_, id) => {
    try {
        const { data: info } = await api.post(
            `/dependencias/getUploadedFiles/${id}`
        );
        const { status, data, message } = info;

        if (status != "success") return { ok: false, message };

        return { ok: true, message, data };
    } catch (err) {
        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };
    }
};

export const saveUpdatedFiles = async(_, { files, id }) => {
    try {
        const formData = new FormData();

        Object.entries(files).forEach(([key, value]) => {
            if (value != null) formData.append(key, files[key]);
        });

        formData.append("project_id", id);
        formData.append("flag", 0);

        const { data: info } = await api.post(
            `/dependencias/uploadFilesDependencia`,
            formData
        );

        const { status, message } = info;
        if (status != "success") return { ok: false, message };

        return { ok: true, message: "ok" };
    } catch (err) {
        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };
    }
};

export const downloadFile = async(_, { file_name, folio }) => {
    try {
        const formData = new FormData();
        formData.append("file_name", file_name);
        formData.append("folio", folio);
        const { data } = await api.post("/dependencias/downloadFile", formData, {
            responseType: "blob",
        });
        const { status, message } = data;
        if (status == "error") return { ok: false, message };

        return { ok: true, message: "ok", data };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

//---------------------------------- INICIO MÉTODOS PARA DialogSendDGGDComponent -------------------------------------------------------
export const sendToDGGD = async(_, folio) => {

    try {

        const { data } = await api.post(`/dependencias/sendToDGGD/${folio}`);
        const { status, message } = data;

        return (status === 'success' || status === 'info') ? { ok: true, message, data, status: status } : { ok: false, message, status }
            /*if (status != "success") return { ok: false, message, status };
            return { ok: true, message: "ok", data, status: status };*/
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

//----------------------------INICIO MÉTODOS PARA DialogChangeRejectedFilesComponent -----------------------------------------------------------------
export const getRejectedFiles = async(_, folio) => {
    try {
        const { data: info } = await api.post("/dependencias/getRejectedFilesNes", {
            folio,
        });

        const { status, data, message, comentario } = info;

        if (status != "success") return { ok: false, message };

        return { ok: true, message, data, comentario };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const saveRejectedFiles = async(_, { files, folio }) => {
    try {
        const formData = new FormData();

        Object.entries(files).forEach(([key, value]) => {
            if (value != null) formData.append(key, files[key]);
        });

        formData.append("folio", folio);

        const { data: info } = await api.post(
            `/dependencias/replaceRejectedFiles`,
            formData
        );

        const { status, message } = info;
        if (status != "success") return { ok: false, message };

        return { ok: true, message: "ok" };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

//-------------------------------INICIO MÉTODOS PARA DialogEditRejectedFilesComponent -------------------------------------------------------------------
export const getUploadedFilesWithStatusHistory = async(_, id) => {
    try {
        const { data: info } = await api.post(
            `/dependencias/getUploadedFilesWithStatusHistory/${id}`
        );
        const { status, data, message } = info;

        if (status != "success") return { ok: false, message };

        return { ok: true, message, data };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

//---------------------------------- INICIO MÉTODOS PARA DialogReSendToDGGDComponent -------------------------------------------------------
export const reSendToDGGD = async(_, folio) => {
    try {
        const { data } = await api.post(`/dependencias/reSendToDGGD/${folio}`);
        const { status, message } = data;
        if (status != "success") return { ok: false, message };

        return { ok: true, message: "ok", data };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};
//--------------------------------INICIO MÉTODOS PARA DialodVerDictamenComponent ---------------------------------------------------------------------------
export const getDictamen = async(_, folio) => {
    try {
        const { data: info } = await api.post(`/dependencias/getDictamen/${folio}`);
        const { status, dictamen, message } = info;

        if (status != "success") return { ok: false, message };

        return { ok: true, message, dictamen };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const downloadFileDictamen = async(_, { file_name, folio }) => {
    try {
        const formData = new FormData();
        formData.append("file_name", file_name);
        formData.append("folio", folio);
        const { data } = await api.post(
            "/dependencias/downloadFileDictamen",
            formData, {
                responseType: "blob",
            }
        );
        const { status, message } = data;
        if (status == "error") return { ok: false, message };

        return { ok: true, message: "ok", data };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};


//----------------------------------Eliminar proyecto -------------------------------------------------------
export const eliminarProyecto = async(_, idProyecto) => {

    try {

        const { data } = await api.post(`/dependencia/eliminarProyecto`, {
            idProyecto: idProyecto
        });

        const { status, message } = data;

        return { status, message };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }
};

export const getAcceptedProjects = async({ commit }) => {
    try {
        const { data: info } = await api.get(`/dependencia/obtenerProyectosAsignadosDependencia`);
        const { status, data } = info;

        if (status != "success") return { ok: false };

        commit("setAceptedProjects", data)

        return { ok: true, data }

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }
};

export const showReports = async({ commit }, idProject) => {

    try {

        const { data: info } = await api.get(`/dependencia/obtenerReportesProyecto/${idProject}`);
        const { status, data, progreso, banderaProgreso, presupuesto, montoGastado, fechaInicioAsignada, fechaFinAsignada } = info;
        commit("setReportsIndex", data);
        return { status, progreso, banderaProgreso, presupuesto, montoGastado, fechaInicioAsignada, fechaFinAsignada };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

}

export const downloadReport = async(_, arrayData) => {

    try {

        const { data } = await api.get(`/dependencia/descargarReporteProyecto/${arrayData[0]}/${arrayData[1]}`, {
            responseType: "blob",
        });

        const { status, message } = data;

        if (status == "error" || status == "success") return { ok: false, message };

        return { ok: true, message: "ok", data };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

}

export const uploadReport = async(_, form) => {

    try {

        const { data } = await api.post(`dependencia/subirReporteProyecto`, form);

        const { status, message } = data;

        return { status, message };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

}

export const downloadDirectoryZip = async(_, idProject) => {

    try {

        const { data } = await api.post(`/consejo/downloadDirectoryZip/${idProject}`, _, {
            responseType: "blob",
        });

        const { status, message } = data;

        if (status == "error") return { ok: false, message };

        return { ok: true, message: "ok", data };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

}

export const sendAssingDates = async(_, form) => {

    try {

        const { data } = await api.post(`/direccion/assingDates`, form);

        const { status, message } = data;

        return { status, message };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

}

export const getObservationFicha = async(_, idProject) => {

    try {

        const { data } = await api.get(`/dependencias/getObservation/${idProject}`);

        const { status, data: info } = data;

        return { status, info };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

}

export const sendSections = async(_, form) => {

    try {

        const { data } = await api.post(`/dependencia/guardarSeccionPlanEstrategico`, form);
        const { message, status } = data;

        return { message, status };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }
}

export const sendSectionsProyecto = async(_, form) => {
    try {

        const { data } = await api.post(`/dependencia/guardarSeccionProyecto`, form);
        const { message, status } = data;

        return { message, status };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }
}

//------------------------- Subir convenio ------------------------------------------//

export const uploadConvenio = async(_, formData) => {

    try {
        const { data } = await api.post(`/dependencia/subirConvenio`, formData);
        return data;

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }
}

//------------------------- Subir convenio ------------------------------------------//

export const getConvenio = async() => {

    try {
        const { data } = await api.get(`/dependencia/obtenerConvenioDependencia`);
        return data;

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }
}

//------------------------- Descargar convenio ------------------------------------------//

export const downloadConvenio = async() => {

    try {
        const { data } = await api.get(`/dependencia/descargarConvenioDependencia`, {
            responseType: "blob",
        });

        return data;

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }
}

//------------------------- Descargar formato convenio ------------------------------------------//

export const downloadFormatoConvenio = async() => {

    try {
        const { data } = await api.get(`/dependencia/descargarFormatoConvenioDependencia`, {
            responseType: "blob",
        });

        return data;

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }
}

//------------------------------------Obtener plan estratégico-------------------------------------------------------------//

export const getPlanEstrategico = async({ commit }) => {


    try {
        const { data } = await api.get(`/dependencia/obtenerPlanEstrategico/0`);

        const { data: dataResponse, status } = data;

        if (status == 'success' || status == 'info') {

            await commit("setPlanEstrategico", dataResponse[0].seccion);
            await commit("setEstadoPlanEstrategico", dataResponse[0].estado);
            await commit("setEstadoPlanEstrategicoId", dataResponse[0].estado_id);
            await commit("setObservacionPE", dataResponse[0].observaciones);

        };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }


}


export const getProyectosFechasSugeridas = async({ commit }) => {

    try {
        const { data } = await api.get(`/dependencia/obtenerProyectosFechasSugeridas`);

        const { data: proyectos, status } = data;

        if (status == 'success') commit("setProyectos", proyectos);

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }


}

//------------------------- Descargar formatos formularios ------------------------------------------//

export const descargarFormatoFormulario = async(_, objectDataArchivo) => {

    try {

        const { data } = await api.get(
            `/dependencia/descargarFormatoFormulario/${objectDataArchivo.idFormulario}/${objectDataArchivo.seccion}/${objectDataArchivo.clave}`, {
                responseType: "blob",
            });

        return data;

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }
}

export const downloadFormType = async(_, typeFormat) => {

    try {

        const { data } = await api.get(
            `/dependencia/descargarFormatoTipologia/${typeFormat}`
        );

        return data;

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }
}


//------------------------- Obtener inventario de proyectos de la dependencia ------------------------------------------//

export const getInventarioProyectos = async({ commit }) => {

    try {

        const { data } = await api.get(`/dependencia/obtenerInventarioDependenciaSistemas`);
        const { data: inventarioProyectos, status } = data;
        if (status == 'success') commit("setInventarioProyectos", inventarioProyectos);

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }
}

export const marcarCompletadoPlanEstrategico = async({ commit }, estado) => {

    try {

        const { data } = await api.post(`/dependencia/marcarCompletadoPlanEstrategico`, {
            estado: estado
        });

        const { data: dataResponse } = await api.get(`/dependencia/obtenerPlanEstrategico/1`);

        if (dataResponse.status == 'success') {

            await commit("setEstadoPlanEstrategico", dataResponse.data[0].estado);
            await commit("setEstadoPlanEstrategicoId", dataResponse.data[0].estado_id);

        }

        const { status, message } = data;

        return { status, message };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }


}

export const getOficinasDependencia = async({ commit }, upp) => {

    try {

        const { data } = await api.get(`/dependencia/obtenerEntidadesUppUr/2/${upp}`);

        const { status, data: oficinas } = data;

        if (status == 'success') commit("setOficinas", oficinas);

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const actualizarEstadoPlanEstrategico = async({ commit }) => {

    try {

        const { data: dataResponse } = await api.get(`/dependencia/obtenerPlanEstrategico/1`);

        if (dataResponse.status == 'success') {

            await commit("setEstadoPlanEstrategico", dataResponse.data[0].estado);
            await commit("setEstadoPlanEstrategicoId", dataResponse.data[0].estado_id);

        }

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}


export const crearProyecto = async(_, formData) => {

    try {

        const { data: dataResponse } = await api.post('/dependencia/crearProyecto', formData);
        //const { data } = await api.post(`/dependencia/guardarSeccionPlanEstrategico`, form);
        const { status, message } = dataResponse;

        return { status, message };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}



export const descargarArchivoPlanEstrategico = async(_, seccion) => {

    try {

        const { data: dataResponse } = await api.get(`/dependencia/descargarArchivoPlanEstrategico/${seccion}`, {
            responseType: "blob",
        });

        return dataResponse;

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const obtenerArchivoPlanEstrategico = async(_, seccion) => {

    try {

        const { data: dataResponse } = await api.get(`/dependencia/obtenerArchivoPlanEstrategico/${seccion}`);

        return dataResponse;

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}


export const savePlanAnual = async(_, formData) => {

    try {

        const { data: dataResponse } = await api.post(`/dependencia/guardarPlanAnual`, formData);

        return dataResponse;

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const getInformacionPlanesAnuales = async({ commit }) => {

    try {

        const { data } = await api.get('/dependencia/obtenerInformacionPlanesAnuales');

        const { status, data: dataResponse } = data;

        if (status == 'success') commit('setInformacionPA', dataResponse);

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const getInformacionPlanAnual = async(_, planAnual) => {
    try {
        const { data } = await api.get(`/dependencia/obtenerInformacionPlanAnual/${planAnual}`);
        const { status, data: dataResponse, file } = data;

        return { status, dataResponse, file };
    } catch (err) {
        let msg = err.response ? err.response.data.message : err.message;
        return { status: 'error', message: msg };
    }
};

export const getFormatoAnalisisFinanciero = async() => {
    try {
        const { data } = await api.get(`/descargarArchivo/AnalisisFinanciero`);
        const { status, data: dataResponse } = data;
        
        return { status, dataResponse };
    } catch (err){
        let msg = err.response ? err.response.data.message : err.message;
        return { status: 'error', message: msg };
    }
}


export const getProjectsByPlanAnual = async(_, planAnual) => {

    try {

        const { data } = await api.get(`/dependencia/obtenerProyectosPorPlanAnual/${planAnual}`);

        const { status, data: dataResponse } = data;
        return { status, dataResponse };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const enviarProyectoRevision = async(_, dataSend) => {

    try {

        const { data } = await api.post('/dependencia/enviarProyectoRevision', {
            idProyecto: dataSend.idProyecto,
            estado: dataSend.estado
        });

        const { status, message } = data;
        return { status, message };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const descargarArchivoProyecto = async(_, dataFile) => {

    try {

        const { idProyecto, seccion, clave, banderaVisualizacion } = dataFile;

        if (banderaVisualizacion == null) {

            const { data } = await api.get(`/dependencia/descargarArchivoProyecto/${idProyecto}/${seccion}/${clave}`, {
                responseType: "blob",
            });

            return { ok: true, message: "ok", data };

        } else {

            const { data } = await api.get(`/dependencia/verArchivoProyecto/${idProyecto}/${seccion}/${clave}`);
            return { ok: true, message: "ok", data };

        }






    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const changeStatusPlanAnual = async(_, objectData) => {

    try {

        const { data } = await api.post('/dependencia/finalizarPlanAnual', objectData);

        const { status, message } = data;

        return { status, message };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const downloadFileObservationPlanes = async(_, dataSend) => {

    try {

        let uri;

        if (dataSend.formulario == 1) {

            uri = `/dependencia/descargarObservacionPlanPE/${dataSend.formulario}/${dataSend.clave}`;

        } else if (dataSend.formulario == 2) {

            uri = `/dependencia/descargarObservacionPlanPA/${dataSend.formulario}/${dataSend.clave}/${dataSend.plan_anual}`;

        } else if (dataSend.formulario == 3) {

            uri = `/dependencia/descargarObservacionFT/${dataSend.formulario}/${dataSend.clave}/${dataSend.idProyecto}`;

        }

        const { data } = await api.get(uri, {
            responseType: "blob"
        });

        return data;

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const obtenerReportePDF = async(_, dataSend) => {

    try {
      
        const { data }   = await api.get(`/dependencia/obtenerReportePDF/${dataSend[0]}/${dataSend[1]}`);
        const { status, file } = data ;
        return  status == 'success' ? { status, file } : { status } ;

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const getInfoReports = async( _, idProyecto ) => {

    try {

        const { data } = await api.get(`/dependencia/reportes/${idProyecto}`);
        return { data };

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

};

export const descargarArchivoRespuesta = async(_, objectData) => {

    try {

        const file = await api.post('/dependencia/descargarArchivoRespuesta', objectData, {
            responseType: "blob"
        });

        return file;

    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }

}

export const enviarReporteT1 = async(_, form) => {

    try {
        
        const { data } = await api.post(`dependencia/subirReporteT1`, form,  { headers: {
            'Accept': 'application/json',
        }});

        const { status, message } = data;

        return { status, message };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};

export const enviarReporteT2 = async(_, form) => {

    try {
        const response = await api.post(`dependencia/subirReporteT2`, form);
        const { data } = response;
        const { status, message } = data;
        return { status, message };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};

export const enviarReporteT3 = async(_, form) => {

    try {
        
        const { data } = await api.post(`dependencia/subirReporteT3`, form,  { headers: {
            'Accept': 'application/json',
        }});

        const { status, message } = data;

        return { status, message };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};


export const obtenerDocumentosReporte = async( _, { idProyecto, clave, date } ) => {

    try {

        const { data } = await api.get(`dependencia/obtenerDocumentosReportes/${idProyecto}/${date}/${clave}`);

        const { status, message, fileBase64  } = data;
        
        return { status, message, fileBase64 };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};

export const obtenerFormatosReporte = async(_, claveFormato) => {

    try {

        const { data } = await api.get(`dependencia/obtenerFormatosReporte/${claveFormato}`);

        const { status, message, fileBase64  } = data;
        
        return { status, message, fileBase64 };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};

export const obtenerFormatos = async( { commit } ) => {

    try {

        const { data } = await api.get(`dependencia/obtenerFormatosDocumentacion`);

        const { status, data:infoData  } = data;
        
        if( status ){
            if( status == "success" )  commit('setFormats', infoData );
        }
        return { status, infoData };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};

export const descargarFormato = async( _, idProyecto ) => {

    try {

        const { data } = await api.get(`dependencia/obtenerFormatoPorId/${idProyecto}`);

        const { status, data:infoData, nombreDocumento, fileBase64  } = data;
        
        if( status ){
            //console.log( status )
        }
        return { status, infoData, nombreDocumento, fileBase64 };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};

export const downloadFormatPlanAnual = async( _, { plan_anual, file_name } ) => {

    try {

        const { data } = await api.get(`dependencia/descargarFormatoPlanAnual/${plan_anual}/${file_name}`);
        return data;

    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};



