import { computed, ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { FilterMatchMode, FilterOperator } from "primevue/api";

const useProjects = () => {
  const store = useStore();
  const columns = ref([
    { field: "folio", header: "Folio" },
    { field: "seccion.1.nombre", header: "Nombre" },
    { field: "dependencia", header: "Dependencia" },
    { field: "fecha_envio_revisor", header: "Fecha de Recepción" },
    { field: "_id", header: "id", hidden: true },
  ]);

  const filters1 = ref({

    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    project_folio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    project_name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    nombre_area: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    reception_date: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

  });

  const getProjects = async () => {
    const resp = await store.dispatch("mesa/getProjectsByStatus");
    return resp;
  };

  const asignarComoRevisor = ( folio, idProyecto ) =>{

    Swal.fire({
      text: 'El proyecto con folio: '+folio+' pasará al módulo revisión de proyectos y se le asignará como revisor.',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: "#6a0f49",
      cancelButtonColor: "#ec85c2",

    }).then(async(result) => {

        if (result.isConfirmed) {
          
          const { status, message } = await store.dispatch("mesa/sendToRevision", idProyecto);
          
          //if(status == 'error') message = 'Ha ocurrido un error'.

          Swal.fire({
            icon: status,
            text:message,
            showConfirmButton: false,
            timer: 3000
          });
      
          if( status == 'success' ) await getProjects();
          
        }

    });

  }

  return {
    getProjects,
    getAllProjects: computed(
      () => store.getters["mesa/getAllProjectsReception"]
    ),
    columns,
    filters1,
    asignarComoRevisor
  };
};

export default useProjects;
