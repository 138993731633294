export default () => ({
    /*project: null,
    projects: null,*/
    dependencia: null,
    inputs: null,
    files: null,
    reportsIndex: null,
    planEstrategico: null,
    proyectos: null,
    inventarioProyectos: null,
    estadoPlanEstrategico: null,
    estadoPlanEstrategicoId: null,
    oficinas: null,
    catalogoImpactosProyecto: null,
    catalogoTiposProyecto: null,
    catalogoPoblacionObjetivo: null,
    catalogoEjecucionProyecto: null,
    catalogoBrindaraServicio: null,
    catalogoPrioridad: null,
    informacionPA: null,
    observacionPE: null,
    formats: []
});