// export const myMutation = ( state ) => {

// }
export const setVentana = (state, ventana) => {
    state.ventana = ventana;
};

/*export const setProject = (state, project) => {
    state.project = project;
};
export const setProjects = (state, projects) => {
    state.projects = projects;
};*/

export const setUserDep = (state, dependencia) => {
    state.dependencia = dependencia;
};

export const setInputsForm = (state, inputs) => {
    state.inputs = inputs;
};

export const setFiles = (state, files) => {
    state.files = files;
};

export const setAceptedProjects = (state, aceptedProjects) => {
    state.aceptedProjects = aceptedProjects;
};

export const setReportsIndex = (state, reportsIndex) => {
    state.reportsIndex = reportsIndex;
};

export const setPlanEstrategico = (state, planEstrategico) => {
    state.planEstrategico = planEstrategico;
};

export const setProyectos = (state, proyectos) => {
    state.proyectos = proyectos;
};

export const setInventarioProyectos = (state, inventarioProyectos) => {
    state.inventarioProyectos = inventarioProyectos;
};

export const setEstadoPlanEstrategico = (state, estadoPlanEstrategico) => {
    state.estadoPlanEstrategico = estadoPlanEstrategico;
}

export const setEstadoPlanEstrategicoId = (state, estadoPlanEstrategicoId) => {
    state.estadoPlanEstrategicoId = estadoPlanEstrategicoId;
}

export const setOficinas = (state, oficinas) => {
    state.oficinas = oficinas;
}

export const setCatalogoImpactosProyecto = (state, catalogoImpactosProyecto) => {
    state.catalogoImpactosProyecto = catalogoImpactosProyecto;
}

export const setCatalogoTiposProyecto = (state, catalogoTiposProyecto) => {
    state.catalogoTiposProyecto = catalogoTiposProyecto;
}

export const setCatalogoPoblacionObjetivo = (state, catalogoPoblacionObjetivo) => {
    state.catalogoPoblacionObjetivo = catalogoPoblacionObjetivo;
}

export const setCatalogoEjecucionProyecto = (state, catalogoEjecucionProyecto) => {
    state.catalogoEjecucionProyecto = catalogoEjecucionProyecto;
}

export const setCatalogoBrindaraServicio = (state, catalogoBrindaraServicio) => {
    state.catalogoBrindaraServicio = catalogoBrindaraServicio;
}

export const setCatalogoPrioridad = (state, catalogoPrioridad) => {
    state.catalogoPrioridad = catalogoPrioridad;
}

export const setInformacionPA = (state, informacionPA) => {
    state.informacionPA = informacionPA;
}

export const setObservacionPE= (state, observacionPE) => {
    state.observacionPE = observacionPE;
}

export const setFormats= (state, formats) => {
    state.formats = formats;
}

