<template>
  <ToolbarVue class="mb-4">
    <template #start>
      <div class="col-12 text-center">
        <h5 class="mb-2 md:m-0 p-as-md-center">
          Equipo Técnico
          <i class="pi pi-users px-2 scalein animation-duration-1000"></i> | 
          Recepción de Proyectos<i class="pi pi-list px-2 scalein animation-duration-1000"></i>
        </h5>
      </div>
    </template>
  </ToolbarVue>
</template>