// export const myMutation = ( state ) => {

// }
export const setProject = (state, project) => {
  state.project = project;
};
export const setProjectsReception = (state, { data }) => {
  state.projects_reception = data;
};

export const setProjectsRevision = (state, { data }) => {
  state.projects_revision = data;
};
export const setInputsForm = (state, inputs) => {
  state.inputs = inputs;
};

export const setRequestProjects = (state, { data }) => {
  state.projects_request = data;
};

export const setConvenios = (state, { data }) => {
  state.convenios = data;
};

export const setDependencias = (state, dependencias) => {
  state.dependencias = dependencias;
};

export const setPlanesDependencia = (state, planesDependencia ) => {
  state.planesDependencia = planesDependencia;
};

export const setCatalogoImpactosProyecto = (state, catalogoImpactosProyecto) => {
  state.catalogoImpactosProyecto = catalogoImpactosProyecto;
}

export const setCatalogoTiposProyecto = (state, catalogoTiposProyecto) => {
  state.catalogoTiposProyecto = catalogoTiposProyecto;
}

export const setCatalogoPoblacionObjetivo = (state, catalogoPoblacionObjetivo) => {
  state.catalogoPoblacionObjetivo = catalogoPoblacionObjetivo;
}

export const setCatalogoEjecucionProyecto = (state, catalogoEjecucionProyecto) => {
  state.catalogoEjecucionProyecto = catalogoEjecucionProyecto;
}

export const setCatalogoBrindaraServicio = (state, catalogoBrindaraServicio) => {
  state.catalogoBrindaraServicio = catalogoBrindaraServicio;
}

export const setCatalogoPrioridad = (state, catalogoPrioridad) => {
  state.catalogoPrioridad = catalogoPrioridad;
}

export const setOficinas = (state, oficinas) => {
  return state.oficinas = oficinas;
}

export const setInputsPA = (state, inputsPA) => {
  state.inputsPA = inputsPA;
};

export const setInputsPE = (state, inputsPE) => {
  state.inputsPE = inputsPE;
};

export const setProyectos = (state, proyectos) => {
  state.proyectos = proyectos;
};

export const setInventarioProyectos = (state, inventarioProyectos) => {
  state.inventarioProyectos = inventarioProyectos;
};

export const setPlanesAnuales = (state, planesAnuales) => {
  state.planesAnuales = planesAnuales;
};

export const setRevisores = (state, revisores) => {
  state.revisores = revisores;
};

export const setProyectosRevisores = (state, proyectosRevisores) => {
  state.proyectosRevisores = proyectosRevisores;
};

export const setCatalogoDependencias = (state, catalogoDependencias) => {
  state.catalogoDependencias = catalogoDependencias;
};

//Admin usuarios ventanas

export const setUsers = (state, { users }) => {
  state.users = users;
};

export const setRoles = (state, roles) => {
  state.roles = roles;
};

export const setAreas = (state, areas) => {
  state.areas = areas;
};

export const setVentanas = (state, ventanas) => {
  state.ventanas = ventanas;
};

export const setTecnicos = (state, tecnicos) => {
  state.tecnicos = tecnicos;
};

export const setProyectosRevision = (state, proyectosRevision) => {
  state.proyectosRevision = proyectosRevision;
};


export const setProyectosResolucion = (state, proyectosResolucion) => {
  state.proyectosResolucion = proyectosResolucion;
};

export const setFormats = (state, formats) => {
  state.formats = formats;
};

export const setExpedients = (state, expedients) => {
  state.expedients = expedients;
};

 