// export const myGetter = (state) => {
// return state
// }
export const getProject = (state) => {
    return state.project;
};

export const getAllInputsForm = (state) => {
    return state.inputs;
};

export const getProyectosList = (state) => {
    return state.allProyects;
};

export const getAllProyectosList = (state) => {
    return state.allProyects;
};

export const getProyectosAceptados = (state) => {
    return state.estado;
};

export const getCatalogoDirecciones = (state) => {
    return state.catalogoDirecciones;
};

export const getCatalogoAsignacionResolucion = (state) => {
    return state.catalogoAsignacionResolucion;
};

export const getPlanesAnuales = (state) => {
    return state.planesAnuales;
};

export const getOficinas = (state) => {
    return state.oficinas;
};


export const getCatalogoImpactosProyecto = (state) => {
    return state.catalogoImpactosProyecto;
}

export const getCatalogoTiposProyecto = (state) => {
    return state.catalogoTiposProyecto;
}

export const getCatalogoPoblacionObjetivo = (state) => {
    return state.catalogoPoblacionObjetivo;
}

export const getCatalogoEjecucionProyecto = (state) => {
    return state.catalogoEjecucionProyecto;
}

export const getCatalogoBrindaraServicio = (state) => {
    return state.catalogoBrindaraServicio;
}

export const getCatalogoPrioridad = (state) => {
    return state.catalogoPrioridad;
}

export const getInputsFormPA = (state) => {
    return state.inputsPA;
};

export const getInputsFormPE = (state) => {
    return state.inputsPE;
};

export const getCatalogoTipoUsuario = (state) => {
    return state.catalogoTipoUsuario;
};

export const getCatalogoAsignacion = (state) => {
    return state.catalogoAsignacion;
};

export const getProyectosResolucion = (state) => {
    return state.proyectosResolucion;
};






