import isAuthenticatedGuard from "@/modules/auth/router/auth-guard";
import isRolAdminGuard from "@/modules/admin/router/admin-rol-guard";
export default {
    beforeEnter: [isAuthenticatedGuard, isRolAdminGuard],
    component: () =>
        import ("@/layouts/MainLayout.vue"),
    props: () => {
        return {
            menuItems: [{
                    label: "Dashboard",
                    icon: "fa-solid fa-chart-column",
                    to: "/admin",
                },
                {
                    label: "Usuarios",
                    icon: "fa-solid fa-user-pen",
                    to: "/admin/accesos",
                },
                {
                    label: "Ventanas de tiempo",
                    icon: "fa-solid fa-clock",
                    to: "/admin/ventana",
                },
            ],
        };
    },
    children: [{
            path: "",
            name: "admin-principal",
            beforeEnter: [isAuthenticatedGuard, isRolAdminGuard],
            component: () =>
                import ("@/modules/admin/views/AdminPrincipalView.vue"),
        },
        {
            path: "accesos",
            name: "admin-accesos",
            beforeEnter: [isAuthenticatedGuard, isRolAdminGuard],
            component: () =>
                import ("@/modules/admin/views/AccesosView.vue"),
        },
        {
            path: "ventana",
            name: "admin-ventana",
            beforeEnter: [isAuthenticatedGuard, isRolAdminGuard],
            component: () =>
                import ("@/modules/admin/views/VentanaView.vue"),
        },
        //     {
        //       path: ":id",
        //       name: "entry",
        //       component: () => import("@/modules/daybook/views/EntryView.vue"),
        //       props: (route) => {
        //         const id = Number(route.params.id);
        //         return {
        //           id: isNaN(id) ? 1 : id,
        //         };
        //       },
        //     },
    ],
};