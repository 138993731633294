import { createStore } from "vuex";

import auth from "../modules/auth/store";
import admin from "../modules/admin/store";
import dependencias from "../modules/dependencia/store";
import mesa from "../modules/mesa/store";
import consejo from "../modules/consejo/store";
import tecnico from "../modules/direccion/store";

let initialState = {
  auth: auth.state(),
  admin: admin.state(),
  dependencias: dependencias.state(),
  mesa: mesa.state(),
  consejo: consejo.state(),
  tecnico: tecnico.state(),
}


const store = createStore({
  modules: {
    auth,
    admin,
    dependencias,
    mesa,
    consejo,
    tecnico,
  },
  mutations: {
    reset (state) {
      Object.keys(state).forEach(key => {
        Object.assign(state[key], initialState[key])
      })  
    }
  }
});

export default store;
