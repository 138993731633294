// export const myMutation = ( state ) => {

// }

export const setRequestDictamenProjects = (state, { data }) => {
    state.request_dictamen_projects = data;
};

export const setProyectosDictaminados = (state, estado) => {
    state.estado = estado;
};

export const setAceptedProjects = (state, aceptedProjects) => {
    state.aceptedProjects = aceptedProjects;
};

export const setSuggestedDates = (state, suggestedDates) => {
    state.suggestedDates = suggestedDates
};

export const setProjectProgress = (state, projectProgress) => {
    state.projectProgress = projectProgress
};

export const setNumProjectsAcccepted = (state, numProjectsAcccepted) => {
    state.numProjectsAcccepted = numProjectsAcccepted
};

export const setReportsIndex = (state, reportsIndex) => {
    state.reportsIndex = reportsIndex;
};