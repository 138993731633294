// export const myAction = async ({commit}) => {

// }

import api from "@/api/laravelApi";

export const getRequestDictamenProjects = async({ commit }, user_id) => {
    try {
        const { data: info } = await api.post(
            "/direccion/getRequestDictamenProjects", { user_id }
        );
        const { status, data, message } = info;

        if (status != "success") return { ok: false, message };

        commit("setRequestDictamenProjects", { data });
        return { ok: true, message };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const saveDictamen = async(
    _, { projectId, dictamen, user_id, file }
) => {
    const formData = new FormData();
    formData.append("id_project", projectId);
    formData.append("dictamen", dictamen);
    formData.append("id_tecnico", user_id);
    formData.append("file", file);

    const { data } = await api.post(`/direccion/saveDictamen`, formData);
    const { status, message } = data;

    if (status != "success") return { ok: false, message };

    return { ok: true, message };
};

export const getUploadedFiles = async(_, id) => {
    try {
        const { data: info } = await api.post(`/direccion/getUploadedFiles/${id}`);
        const { status, data, message, dictamen } = info;

        if (status != "success") return { ok: false, message };

        return { ok: true, message, data, dictamen };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const downloadFile = async(_, { file_name, projectId, flagTable }) => {
    try {
        const formData = new FormData();
        formData.append("file_name", file_name);
        formData.append("id_project", projectId);
        formData.append("flagTable", flagTable);
        const { data } = await api.post("/direccion/downloadFile", formData, {
            responseType: "blob",
        });
        const { status, message } = data;
        if (status == "error") return { ok: false, message };

        return { ok: true, message: "ok", data };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const sendToMA = async(_, { projectId }) => {
    try {
        const { data } = await api.post(`/direccion/sendToMA/${projectId}`);
        const { status, message } = data;

        if (status != "success") return { ok: false, message };

        return { ok: true, message };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

//GET INPUTS OF FORM FOR PROJECT FICHA
export const getInputsForm = async() => {
    try {

        const { data: info } = await api.post("/dependencias/getDataFormRegister");
        const { status, data, message } = info;

        if (status != "success") return { ok: false, message };

        return { ok: true, message, data };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }
};

export const getInfoInputsForm = async(_, projectId) => {

    try {

        const { data: info } = await api.post(`/general/getFichaProject/${projectId}`);
        const { status, data, message } = info;

        return status != "success" ? { ok: false, message } : { ok: true, message, data }

    } catch (err) {

        let msg;

        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }

        return { ok: false, message: msg };

    }
}

export const getProyectosDictaminados = async({ commit }) => {

    try {
        const { data: info } = await api.post(`/general/getFichaProject/8`);
        const { status, data, message } = info;

        if (status != "success") return { ok: false, message };

        commit("setProyectosDictamen", data);
        return { ok: true, message, data };

    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }

}

//Obtener todos los proyectos aceptados

export const obtenerProyectos = async({ commit }) => {
    try {
        const { data: info } = await api.get(`/direccion/obtenerProyectosSeguimiento`);
        const { status, data, progress } = info;
        if (status != "success") return { ok: false };
        commit("setAceptedProjects", data)
        return { ok: true, progress }
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

// Obtener las fechas asignasdas

export const getSuggestedDates = async({ commit }, projectId) => {

    try {

        const { data: info } = await api.get(`/direccion/getSuggestedDates/${projectId}`);
        const { status, data, message } = info;

        commit("setSuggestedDates", data)

        return status != "success" ? { ok: false, message } : { ok: true, message, data }

    } catch (err) {

        let msg;

        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }

        return { ok: false, message: msg };

    }
}


export const sendDates = async(_, form) => {

    try {

        const resp = await api.post(`/direccion/assingDates`, form);
        return resp

    } catch (err) {

        let msg;

        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }

        return { ok: false, message: msg };

    }
}
export const cargarRepo = async(_, form) => {
    try {

        const data = await api.post(`dependencias/createReportProject`, form);
        return data;

    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
}

export const downloadRepo = async(_, { projectId }) => {
    try {

        const { data } = await api.post(`consejo/downloadDirectoryZip${projectId}`);
        const { status, message } = data;
        if (status == "error") return { ok: false, message };

        return { ok: true, message: "ok", data };
    } catch (err) {
        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };
    }
};

export const showReports = async({ commit }, idProject) => {

    try {

        const { data: info } = await api.get(`/direccion/obtenerReportesProyecto/${idProject}`);
        const { status, data, progreso, banderaProgreso, presupuesto, montoGastado, fechaInicioAsignada, fechaFinAsignada } = info;
        commit("setReportsIndex", data);
        return { status, progreso, banderaProgreso, presupuesto, montoGastado, fechaInicioAsignada, fechaFinAsignada };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

}

export const uploadReport = async(_, form) => {

    try {

        const { data } = await api.post(`direccion/subirReporteProyecto`, form);

        const { status, message } = data;

        return { status, message };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

}

export const obtenerReporte = async( _, data ) => {

    try {   
           
        if( data.action == 1 ){
           
            const { data: file } = await api.get(`/direccion/descargarReporteProyecto/${data.idProyecto}/${data.mes}`,{
                responseType: "blob",
            }); 
            
            return file;

        }else if( data.action == 2 ){
            
            const { data: dataResponse } = await api.get(`/direccion/obtenerReportePDF/${data.idProyecto}/${data.mes}`);
            const { status, file } = dataResponse;
            if( status == 'success') return { status, file };
            else return { status };

        }


    } catch (err) {

        let msg;
        (err.response) ? msg = err.response.data.message: msg = err.message;
        return { status: 'error', message: msg };

    }    

};


export const subirPrimerReporte = async(_, form) => {

    try {

        const { data } = await api.post(`direccion/subirPrimerReporteProyecto`, form);

        const { status, message } = data;

        return { status, message };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};

export const obtenerDocumentosReporte = async(_, {idProyecto, clave}) => {

    try {

        const { data } = await api.get(`direccion/obtenerDocumentosReporte/${idProyecto}/${clave}`);

        const { status, message, fileBase64  } = data;
        
        return { status, message, fileBase64 };

    } catch (err) {

        let msg;
        if (err.response) {
            msg = err.response.data.message;
        } else if (err.request) {
            msg = err.request;
        } else {
            msg = err.message;
        }
        return { ok: false, message: msg };

    }

};
