import store from "@/store";

const isConsejoGuard = (to, from, next) => {
  const arrayUserType = [
    "admin",
    "dependencia",
    "revisor",
    "consejo",
    "direcciones",
  ];
  if (store.state.auth.user.tipo_usuario.id === 3) next();
  else next(`/${arrayUserType[store.state.auth.user.tipo_usuario.id - 1]}`);
};

export default isConsejoGuard;
