import store from "@/store";
import Swal from "sweetalert2";

const isAuthenticatedGuard = async(to, from, next) => {

    const { ok } = await store.dispatch("auth/verifyAuth");

    if (ok) next();
    else 
    {
        Swal.fire({
            icon: 'info',
            iconColor:  "#6a0f49",
            title: 'Tu sesión expiró',
            text: 'Tu sesión ha expirado, vuelve a acceder al sistema ingresando tus credenciales',
            showConfirmButton: false,
            timer: 3500
        });

        next({ name: "login" });
    }

};

export default isAuthenticatedGuard;