// export const myGetter = (state) => {
// return state
// }
export const getVentana = (state) => {
    return state.ventana;
};
/*export const getProject = (state) => {
    return state.project;
};
export const getAllProjects = (state) => {
    return state.projects;
};*/

export const getUserDep = (state) => {
    return state.dependencia;
};

export const getAllInputsForm = (state) => {
    return state.inputs;
};

export const getAllFiles = (state) => {
    return state.files;
};

export const getAceptedProjects = (state) => {
    return state.aceptedProjects;
};

export const getReportsIndex = (state) => {
    return state.reportsIndex;;
};

export const getPlanEstrategico = (state) => {
    return state.planEstrategico;
};

export const getProyectos = (state) => {
    return state.proyectos;
};

export const getInventarioProyectos = (state) => {
    return state.inventarioProyectos;
}

export const getEstadoPlanEstrategico = (state) => {
    return state.estadoPlanEstrategico;
}

export const getEstadoPlanEstrategicoId = (state) => {
    return state.estadoPlanEstrategicoId;
}

export const getOficinas = (state) => {
    return state.oficinas;
}

export const getCatalogoImpactosProyecto = (state) => {
    return state.catalogoImpactosProyecto;
}

export const getCatalogoTiposProyecto = (state) => {
    return state.catalogoTiposProyecto;
}

export const getCatalogoPoblacionObjetivo = (state) => {
    return state.catalogoPoblacionObjetivo;
}

export const getCatalogoEjecucionProyecto = (state) => {
    return state.catalogoEjecucionProyecto;
}

export const getCatalogoBrindaraServicio = (state) => {
    return state.catalogoBrindaraServicio;
}

export const getCatalogoPrioridad = (state) => {
    return state.catalogoPrioridad;
}

export const getInformacionPA = (state) => {
    return state.informacionPA;
}

export const getObservacionPE = (state) => {
    return state.observacionPE;
}

export const getFormats = (state) => {
    return state.formats;
}
