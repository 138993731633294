<template>
  <DataTableVue
    :value="projects"
    stripedRows
    :paginator="true"
    :rows="10"
    paginatorTemplate="CurrentPageReport  PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown"
    :rowsPerPageOptions="[10, 20, 30]"
    responsiveLayout="scroll"
    removableSort
    dataKey="project_folio"
    v-model:filters="filters1"
    :scrollable="true"
    scrollHeight="flex"
    class="p-datatable-sm"
    style="width: 100%"
  >
    <template #header>
      <div class="flex flex-column md:flex-row md:justiify-content-between">
        <div class="col flex justify-content-end md:justiify-content-center">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputTextV
              v-model="filters1['global'].value"
              placeholder="Buscar"
            />
          </span>
        </div>
      </div>
    </template>
    <template #empty> No se encontraron resultados. </template>
    <ColumnVue
      v-for="col of columns"
      :field="col.field"
      :header="col.header"
      :key="col.field"
      :sortable="true"
      :hidden="col.hidden"
    >
    </ColumnVue>
    <ColumnVue :exportable="false" style="min-width: 8rem" header="Opciones">
      <template #body="slotProps">
        <!--<ButtonVue
          label="Revisar Proyecto"
          icon="pi pi-search"
          @click="
            openDialog(
              'checkProject',
              slotProps.data._id,
              slotProps.data.folio
            )
          "
          class="p-button-success p-button-sm"
        />-->
         <ButtonVue 
            class="p-button-rounded bg-blue-400 m-1" 
            size="small"
            icon="pi pi-user-edit" 
            severity="success" 
            aria-label="Search"
            @click="asignarComoRevisor(slotProps.data.folio, slotProps.data._id)"
            v-tooltip.top="'Asignarme como revisor del proyeto con folio: '+slotProps.data.folio"
        />
      </template>
    </ColumnVue>
  </DataTableVue>
  <DialogCheckProjectComponent v-if="hasRole('checkProject')" />
</template>

<script>
import { onMounted } from "vue";
import Swal from "sweetalert2";
import useProjects from "../../composables/RecepcionProyectos/useProyectos";
import useOpenDialogs from "../../composables/RecepcionProyectos/useOpenDialogs";

export default {

  setup() {
    const { getAllProjects, filters1, columns, getProjects, asignarComoRevisor } = useProjects();

    const { openDialog, hasRole } = useOpenDialogs();

    onMounted(async () => {
      new Swal({
        title: "Cargando información...",
        allowOutsideClick: false,
      });

      Swal.showLoading();
      await getProjects();
      //if (!ok) Swal.fire("Error", message, "error");
      Swal.close();

    });

    return {
      projects: getAllProjects,
      filters1,
      columns,
      hasRole,
      openDialog,
      asignarComoRevisor
    };
  },
};
</script>

<style>
</style>