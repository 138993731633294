<template>
    <ToolbarVue class="mb-4">
        <template #start>
            <div class="col-12">
                <h5 class="mb-2 md:m-0 p-as-md-center">
                    Dependencia
                    <i class="fa fa-shield px-1"
                        style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i> |
                    Dashboard
                    <i class="pi pi-chart-bar px-1"
                        style="--fa-animation-iteration-count: 1; --fa-animation-duration: 2.5s;"></i>
                </h5>
            </div>
        </template>
    </ToolbarVue>
    <PanelVue class="no-header">
        <Message icon="true" severity="info" :closable="false">
            <div class="grid">
                <!--<div class="col-6 sm:col-6  w-4 m2">
                    <i class="pi pi-chart pi-chart-bar" style="font-size: 2rem"></i>
                </div>-->
                <div class="col-4 sm:col-6">
                    <Dropdown v-model="selectedTypeChart" :options="optionsGraph" optionLabel="name" optionValue="code"
                        placeholder="Grafica - pastel - dona" />
                </div>
                <div class="col-4 sm:col-6">
                    <Dropdown v-model="selectedTypeInfo" :options="optionsGraph" optionLabel="name" optionValue="code"
                        placeholder="Prioridad - Proyectos" />
                </div>
            </div>
        </Message>
        <div class="grid flex align-items-center justify-content-center">
            <div class="col-6 w-4 m-5">
                <Chart class="p-chart" style="min-height: 350px; height: 350px; max-height: 350px;" :type="selectedTypeChart"
                    :data="chartData" :options="lightOptions" />
                <!-- <Message icon="false" severity="info" :closable="false">Total de proyectos:</Message>-->
            </div>
            <Divider layout="vertical" class="p-divider p-component p-divider-vertical p-divider-solid p-divider-center" />
            <div class="col-6 w-4 m-5">
                <Chart class="p-chart" style="min-height: 350px; height: 350px; max-height: 350px;"
                    :type="selectedTypeChart" :data="chartData" :options="lightOptions" />
            </div>
        </div>
    </PanelVue>
    <Message icon="false" severity="info" :closable="false">
        Total de proyectos: 5 <br>
    </Message>
    <hr>
</template>
<!--style="min-height: 350px; height: 350px; max-height: 350px;" -->
<script>

import { ref, onMounted, watch } from 'vue';
import Dropdown from 'primevue/dropdown';
import Chart from 'primevue/chart';
import Divider from 'primevue/divider';
import Message from 'primevue/message';
//import useCharts from "../composables/useCharts";

export default {

    components: {

        Chart,
        Divider,
        Message,
        Dropdown

    },

    

    setup() {

        /* const {
 
            //objData
 
         } = useCharts();*/
        const selectedTypeChart = ref('bar');
        const optionsGraph = ref([
            { name: 'Grafica - Barra', code: 'bar' },
            { name: 'Grafica - Pastel ', code: 'pie' },
            { name: 'Grafica - Dona', code: 'doughnut' },
            { name: 'Grafica - Área Polar', code: 'polarArea' },
        ]);

        const chartData = ref();

        const lightOptions = ref({

        });

        watch(selectedTypeChart, ()=>{
            if (chartData.value) {
                if (selectedTypeChart.value == 'pie') {
                    lightOptions.value = {
                        plugins: {
                            legend: {
                                labels: {
                                    usePointStyle: true
                                }
                            }
                        }
                    }
                } else if (selectedTypeChart.value == 'bar') {
                    lightOptions.value = {
                        scales: {
                            y: {
                                beginAtZero: true
                            }
                        }
                    }
                }else if (selectedTypeChart.value == 'doughnut'){
                    lightOptions.value = {
                        cutout: '60%'
                    }
                }
            }else{
                lightOptions.value = {
                     
                    }
            }
            chartData.value = setChartData();
        })

        
        onMounted(() => {
            chartData.value = setChartData();
        });


        const setChartData = () => {
            const documentStyle = getComputedStyle(document.body);

            return {
                labels: ['A', 'B', 'C'],
                datasets: [
                    {
                        label:"",
                        data: [540, 325, 702],
                        backgroundColor: [documentStyle.getPropertyValue('--blue-500'), documentStyle.getPropertyValue('--yellow-500'), documentStyle.getPropertyValue('--green-500')],
                        hoverBackgroundColor: [documentStyle.getPropertyValue('--blue-400'), documentStyle.getPropertyValue('--yellow-400'), documentStyle.getPropertyValue('--green-400')]
                    }
                ]
            };
        };

        return {
            chartData,
            lightOptions,
            selectedTypeChart,
            optionsGraph
        }

    }
}
</script>
<style>
.p-panel .p-panel-header {
    padding: 0 !important;
}
</style>