// export const myMutation = ( state ) => {

// }
export const setProject = (state, project) => {
    state.project = project;
};

export const setProyectosList = (state, estado) => {
    state.estado = estado;
};

export const setAllProyectosList = (state, estado) => {
    state.allProyects = estado;
};

export const setProyectosAceptados = (state, estado) => {
    state.estado = estado;
};

export const setInputsForm = (state, inputs) => {
    state.inputs = inputs;
};

export const setCatalogoDirecciones = (state, catalogoDirecciones) => {
    state.catalogoDirecciones = catalogoDirecciones;
};

export const setCatalogoAsignacionResolucion = (state, catalogoAsignacionResolucion) => {
    state.catalogoAsignacionResolucion = catalogoAsignacionResolucion;
};

export const setPlanesAnuales = (state, planesAnuales) => {
    state.planesAnuales = planesAnuales;
};

export const setOficinas = (state, oficinas) => {
    state.oficinas = oficinas;
};

export const setCatalogoImpactosProyecto = (state, catalogoImpactosProyecto) => {
    state.catalogoImpactosProyecto = catalogoImpactosProyecto;
}

export const setCatalogoTiposProyecto = (state, catalogoTiposProyecto) => {
    state.catalogoTiposProyecto = catalogoTiposProyecto;
}

export const setCatalogoPoblacionObjetivo = (state, catalogoPoblacionObjetivo) => {
    state.catalogoPoblacionObjetivo = catalogoPoblacionObjetivo;
}

export const setCatalogoEjecucionProyecto = (state, catalogoEjecucionProyecto) => {
    state.catalogoEjecucionProyecto = catalogoEjecucionProyecto;
}

export const setCatalogoBrindaraServicio = (state, catalogoBrindaraServicio) => {
    state.catalogoBrindaraServicio = catalogoBrindaraServicio;
}

export const setCatalogoPrioridad = (state, catalogoPrioridad) => {
    state.catalogoPrioridad = catalogoPrioridad;
}

export const setInputsFormPA = (state, inputsPA) => {
    return state.inputsPA = inputsPA;
};

export const setInputsFormPE = (state, inputsPE) => {
    return state.inputsPE = inputsPE;
};

export const setCatalogoTipoUsuario = (state, catalogoTipoUsuario) => {
    return state.catalogoTipoUsuario = catalogoTipoUsuario;
};

export const setCatalogoAsignacion = (state, catalogoAsignacion) => {
    return state.catalogoAsignacion = catalogoAsignacion;
};

export const setCatalogoDireccion = (state, catalogoDireccion) => {
    return state.catalogoDireccion = catalogoDireccion;
};


export const setProyectosResolucion = (state, proyectosResolucion) => {
    return state.proyectosResolucion = proyectosResolucion;
};









