// export const myMutation = ( state ) => {

// }

export const setUsers = (state, { users }) => {
    state.users = users;
};

export const setRoles = (state, roles) => {
    state.roles = roles;
};

export const setAreas = (state, areas) => {
    state.areas = areas;
};

export const setVentanas = (state, ventanas) => {
    state.ventanas = ventanas;
};

export const setTecnicos = (state, tecnicos) => {
    state.tecnicos = tecnicos;
};

export const setDependencias = (state, dependencias) => {
    state.dependencias = dependencias;
};