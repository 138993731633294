import { ref } from "vue";

const dialog = ref({
  role: [],
});

const projectId = ref(null);
const folio = ref(null);

const useOpenDialogs = () => {

  const toggleDialog = () => {
    dialog.value.role.pop();
    projectId.value = null;
    folio.value = null;
  };

  const openDialog = (role, project, foli) => {
    projectId.value = project;
    folio.value = foli;
    dialog.value.role.push({ type: role, isOpen: true });
  };

  const hasRole = (role = "") => {
    if (role === "") return false;
    const findRole = dialog.value.role.find((currentRole) =>
      currentRole.type === "" ? null : currentRole.type === role
    );
    if (findRole === undefined) return false;

    return findRole.type === role && findRole.isOpen === true ? true : false;
  };

  return {
    toggleDialog,
    openDialog,
    hasRole,
    projectId,
    folio,
  };
  
};

export default useOpenDialogs;
