// export const myGetter = (state) => {
// return state
// }

export const getAllUsers = (state) => {
    return state.users;
};

export const getRoles = (state) => {
    return state.roles;
};

/*export const getAreas = (state) => {
    return state.areas;
};*/

export const getVentanas = (state) => {
    return state.ventanas;
};

export const getTecnicos = (state) => {
    return state.tecnicos;
};

export const getDependencias = (state) => {
    return state.dependencias;
};